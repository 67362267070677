.edp-laops {
  &__card {
    min-height: $card-minimum-height-default !important;
  }
}

@media only screen and (min-width: $breakpoint-xl) {
  // set the height specifically for cards and for `happy face by area` card
  .edp-laops {
    &__card {
      height: 21vw !important;
    }
    &__qsm-trend-card {
      min-height: $card-minimum-height-default;
      height: 51vw !important;
    }
    &__7dfs-car-park {
      min-height: 190px;
      height: 35vw !important;
    }
    &__card-padding {
      padding-bottom: $inner-card-padding;
    }
  }
}

@media only screen and (max-width: $breakpoint-xl) {
  // set the height specifically for `happy face by area` card
  .edp-laops {
    &__qsm-trend-card {
      min-height: $card-minimum-height-default;
      height: 57vw !important;
    }
    &__7dfs-car-park {
      min-height: 190px;
      height: 50vw !important;
    }
  }
}

@media only screen and (max-width: $breakpoint-md) {
  .edp-laops {
    &__card-padding {
      padding-bottom: $inner-card-padding;
    }
  }
}
