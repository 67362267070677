$notification-pop-up-border-width: 1px;
$notification-width: 20.5em;
$notification-border-color: #999;

@mixin smallText {
    font-size: $font-size-xs;
    color: $notification-small-color;
    line-height: 1.1em;
}

@mixin emphasizedSmallText {
    font-weight: $font-weight-bold;
    line-height: 1.1em;
}

@mixin titleText {
    font-size: $font-size-sm;
    line-height: 1.1em;
}

.edp-notification {
    width: $notification-width;
    background-color: #FFF;
}

.edp-notification--clickable {
    cursor: pointer;
}

.edp-notification-pop-up {
    &__overrides {
        color: $dark-font-color;
        padding: 0;
        width: $notification-width;
        &:hover {
            cursor: default;
        }
    }
}

.edp-notification-center {
    position: fixed;
    right: 0;
    z-index: 1000;
    bottom: 0;
    top: 0;
    background: #191919;
    box-shadow: 0 0 20px 4px rgba(0, 0, 0, 0.5);
    overflow-y: auto;
    width: $notification-width;
    z-index: 10;

    .edp-notification {
        width: 100%;
    }

    &__title-section {
        color: $light-font-color;
        margin: 20px 24px;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
    }
    &__title {
        font-size: $font-size-md;
        font-weight: $font-weight-bold;
        margin-bottom: 0;
    }
    &__checkbox-group {
        label {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 0;
        }
        input {
            width: auto;
        }
    }
    
    &__nav {
        justify-content: space-around;
        margin-top: 12px;
        color: $light-font-color;
        .nav-item .nav-link {
            color: $light-grey-1;
            font-size: $font-size-base;
            position: relative;
            padding: 0 12px 12px 12px;
            cursor: pointer;
        }
        .active {
            font-weight: $font-weight-bold;
            &:after {
                content: "";
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100%;
                height: 4px;
                background-color: $golden;
            }
        }
    }


    &__notification {
        border-bottom: 1px solid $notification-border-color;
        &:first-child {
            border-top: 1px solid $notification-border-color;
        }
    }

    &__notification-section__title {
        padding: 0.4em 1.2em;
        font-size: $font-size-sm;
        background-color: #d5d5d5;
        border-bottom: 1px solid $notification-border-color;
    }

    &__indicator {
        line-height:0px;
        border-radius:50%;
        background-color: #fff;
        border-radius: 50%;
        font-size: $font-size-index;
        color: #000;
        right: -5px;
        bottom: -1px;
        position: absolute;
        text-align: center;
    }
    &__indicator span {
        display:inline-block;
        padding-top:50%;
        padding-bottom:50%;
    }
    &__empty-prompt {
        display: inline-block;
        margin-top: 40px;
        color: $light-font-color;
        font-size: $font-size-sm;
    }
}

@media (min-width: $breakpoint-md) {
    .edp-notification-center {
       top: $navbar-height;
    }
}

/* EDP Notification - used by AMS and Beontra */
.edp-notification {
    .edp-notification__content {
        @include smallText();
    }
    height: 100px;
    display: flex;
    &.is-unread {
        background-color: $notification-unread-bg;
    }
    &:hover {
        background-color: $notification-hover-bg;
    }
    &__highlight {
        width: 16px;
        height: 100%;
        &.red { 
            background-color: $red-alert-color;
        }
        &.amber { 
            background-color: $amber-alert-color;
        }
        &.green { 
            background-color: $green-alert-color;
        }
        &.info { 
            background-color: $info-alert-color;
        }
    }
    &__icon-section {
        display: flex;
        align-items: center;
        margin-left: 14px;
        margin-right: 14px;
    }
    &__icon {
        height: 40px;
        width: 40px;
    }
    &__body {
        display: flex;
        flex-direction: column;
        padding-top: 16px;
        padding-bottom: 16px;
        height: 100%;
        flex: 1;
        font-size: $font-size-xs;
    }
    &__title {
        width: 100%;
        padding-bottom: 10.5px;
        @include titleText();
    }
    &__buttons {
        flex: 1;
        display: flex;
        align-items: flex-start;
        justify-content: flex-end;
    }
    &__actions {
        display: flex;
        flex-direction: column;
        padding-top: 16px;
        padding-bottom: 16px;
        margin-left: -4px;
        margin-right: 16px;
        height: 100%;
        width: 64px;
        text-align: right;
    }
    &__timestamp {
        @include smallText();
    }
    &__buttons {
        button {
            line-height: 0;
        }
        button + button {
            margin-left: 10px;
        }
        .edp-notification__close svg {
            width: 12px;
            height: 12px;
            stroke: #999;
        }
    }
}

/* AMS Notification override */
.edp-notification--ams {
    .edp-notification__content {
        display: flex;
    }
    &__time {
        line-height: 1.1em;
        .type,
        .date {
            @include smallText();
        }
        .time {
            @include emphasizedSmallText();
        }
    }
    &__time-divide {
        margin-left: 16px;
        margin-right: 16px;
        align-self: center;
        text-align: center;
        font-size: 9px;
    }
}

/* Beontra Notification override */
.edp-notification--beontra {
    .at {
        font-weight: $font-weight-normal;
    }
    .forecast-icon {
        display: inline-block;
        margin-right: 5px;
        position: relative;
        top: 2.5px;
        // CSS triangle (potentially replace with SVG)
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 4.3px 8px 4.3px;
        border-color: transparent transparent currentColor transparent;
        &.decrease {
            transform: rotate(180deg);
        }
    }
}

/* Happy Face Notification override */
.edp-notification--happy-face {
    .edp-notification__content {
        font-size: $font-size-sm;
    }
}

/* TomTom Notification override */
.edp-notification--tomtom {
    cursor: pointer;
    .edp-notification__icon-section {
      margin-left: 5px;
      margin-right: 6px;
    }
  
    .edp-notification__icon-section img {
      width: 55px;
    }

    .edp-notification__actions {
        width: 34px;
    }
}