// Bootstrap (reactstrap) overrides
// Navigation Bar

.navbar-brand {
  font-size: 1rem !important;
  margin-top: auto;
  margin-bottom: auto;
}

.navbar-toggler {
  border-color: rgba(255, 255, 255, 0.1);
  margin-right: 0.4em;
}

.custom-toggler .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255,0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

.navbar-toggler:focus {
  outline: none;
}

.navbar-toggler-icon:focus {
  outline: none;
}

.nav-link:nth-child(n+2):last-child{
  padding-right: 0;
}

@media (min-width: $breakpoint-sm) {
.navbar-expand-sm .navbar-nav .nav-link {
  padding-left: 0.8rem;
  padding-right: 0.8rem;
}
}

.navbar {
  background-color: $navbar-bg-color;
  height: $navbar-height;
  padding: 0;

  .dropdown-toggle {
    background: transparent;
    border: transparent;
    border-radius: 0;
  }

  .sign-out-dropdown, .contact-us-dropdown {
    transform: none !important;
  }

  .nav-item {
    font-weight: $font-weight-thin;
  }

  .sign-out {
    background-color: $gray-300;
    color: #000;
  }
}

ul, menu, dir {
  margin-left: 0;
  padding-inline-start: 0;
  padding-left: 0;
}
// remove gutter and adjust width accordingly for pages
.remove-gutter {
  margin-left: -15px;
  margin-right: -15px;
}

a:hover {
  text-decoration: none;
}

a.homepage-button:hover {
  color: #353735;
  text-decoration: none;
}

// Card
.card {
  background-color: $gray-200;
  border: 1px solid $gray-200;
  border-radius: 4px;
  overflow: hidden;
  &--status {
    font-size: $font-size-sm;
  }
}
// Progress

.progress-sm {
  height: 1rem;
  margin-bottom: 1rem;
}

.progress-xs {
  height: 5px;
  margin-bottom: 0.5rem;
}

// Breadcrumb

.breadcrumb {
  font-size: $font-size-sm;
  margin-top: -15px;

  > .active {
    font-weight: 600;
  }

  > li + li::before {
    color: $gray-light;
  }
}

// Form

.form-control {
  font-size: $font-size-base;

}

.input-sm {
  font-size: 13px;
}

// Alerts

.alert {
  font-size: $font-size-sm;

  .close {
    color: rgba(#000, 0.4);
  }
}

.alert-sm {
  padding: 10px 15px;

  .close {
    font-size: 20px;
    right: -8px;
    top: 0;
  }
}

iframe {
  border: 0;
}

.login {
  background: $gray-200;
  border: 1px solid $gray-300;
  border-radius: 2px;
  padding: 40px 30px 20px;
  &__header {
    font-weight: $font-weight-thin;
    margin-bottom: 20px;
  }
}

.noselect {
  -khtml-user-select: none; 
  -moz-user-select: none; 
  -ms-user-select: none; 
  -webkit-touch-callout: none; 
  -webkit-user-select: none; 
  user-select: none; 
}

// Notification (react-toastify)
.Toastify__toast-container--top-right {
  top: calc(#{$navbar-height} + 2em);
  right: 1.75em; 
}

@media (min-width: $breakpoint-lg) {
  .Toastify__toast-container--top-right {
    top: calc(#{$navbar-height} + 0.5em);
  }
}


// highcharts gantt chart dropdown items
.assetCleansTooltip span {
  z-index: 999 !important;
  background-color: #343434 !important;
  position: absolute !important;
  top: 0px !important;
  left: 0px !important;
  right: 0px !important;
  bottom: 0 !important;
  padding: 17px !important;
  margin: 0 !important;
  width: 237px !important;
  height: 125px !important;
}
