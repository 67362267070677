@import './utils/custom-variables';
@import '~bootstrap/scss/bootstrap';
@import 'overrides';

// pages
@import './utils/nav-side-bar';
@import './pages/passenger-flow';
@import './pages/landing';
@import './pages/help-centre';
@import './pages/user-permissions';
@import './pages/laops';


// utilities
@import './utils/navigation';
@import './utils/loading';
@import './utils/main';
@import './utils/board';
@import './cards/card';
@import './utils/filter';
@import './cards/queueTimes';
@import './cards/dhhs-risk';
@import './utils/button';
@import './utils/tooltip';
@import './utils/modal';
@import '~react-toastify/dist/ReactToastify.css';
@import './utils/input-range-slider';
@import './utils/notifications';
@import './utils/happyface';
@import './animate.css';
@import './utils/printing';
@import './utils/animation';

// cards
@import './cards/flights-breakdown';
@import './cards/flights';
@import './cards/arterial-road-status';
@import './cards/internal-roads';
@import './cards/parking';
@import './cards/card-notifications';
@import './cards/card-layout';
@import './cards/weather';

@import './pages/ioc';