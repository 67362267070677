
$grey-toggle-color: rgba(255,255,255,0.3);
.generic-message {
  display: flex;
  justify-content: center;
  align-items: center;
  // color: #585858;
  font-weight: lighter;
  color: #C7C8CB;
  font-size: 20px;
  width: 100%;
  height: 100%;
}
.edp-card {
  // set the default height of the card
  &__generic {
    height: 300px;
  }
  &--footer {
    color: $grey-toggle-color;
    font-size: 0.9rem;
    /* opacity: 0.5; */
    margin: 0px 0 0 0px;
    float: left;
    .text{
      opacity: 0.5;
    }
    .icon img{
      margin-top: -0.3rem;
    }
  }
  height: 100%;
  width: 100%;
  position: relative;
  &--collapsed {
    height: auto;
  }
  &__links {
    display: flex;
    justify-content: space-around;
    align-items: center;
    background: $card-bg;
    height: 62px;
    font-size: 14px;
  }
  &__items {
    padding: 5px 15px;
    display: flex !important;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 12px;
    &--link {
      color: $light-font-color;
      display: block;
      &:hover {
        color: $grey-toggle-color;
        .edp-card__icon {
          filter: brightness(0) invert(0.5);
        }
      }
    }
  }
  &__icon {
    margin-left: 5px;
    svg {
      transform: rotate(45deg);
      font-size: 1em;
    }
  }
  &__button {
    color: $grey-toggle-color;
    font-weight: 100;
    font-size: 24px;
    border: 1px solid $grey-toggle-color;
    border-radius: 100%;
    height: 30px;
    background: transparent;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: none !important;
  }
  &__basic {
    min-width: 0;
    flex-basis: 100%;
  }
  /* styling for top navigation of a card */
  &__nav {
    height: 62px !important;
    padding-left: 1em !important;
    .navbar-nav {
        height: 100%;
        width: 100%;
    }
    background: $card-bg;
    padding: 0 15px;
    font-size: $card-nav-font-size;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    margin-bottom: -2px;
    &--small {
      font-size: $font-size-sm;
      .edp-card__nav-item {
        padding: 15px 0 10px;
      }
    }
    &--right-aligned {
      padding-right: 24px !important
    }
    &--space-between {
      .navbar-nav {
        justify-content: space-around;
        align-items: center;
        .nav-item {
          margin: 0; 
        }
      }
    }
  }
  &__nav-item {
    height: 100%;
    color: $light-font-color;
    font-weight: $font-weight-thin;
    padding: 15px 0;
    margin: 0 20px 0px 5px;
    border-bottom: 5px solid;
    border-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 80px;
    &:hover {
        cursor: pointer;
    }
    &.active {
        border-bottom: 5px solid;
        border-color: $brand-warning;
        font-weight: $font-weight-semi-bold;
    }
  }
  &__header {
    display: flex;
    align-items: flex-start;
    &--space-between {
      justify-content: space-between;
    }
    > .row {
      width: 100%;
    }
  }
  &__header,
  &__dropdown-header {
    padding-bottom: $card-content-bottom-padding-large;
  }
  &__header {
    &--reduced {
      padding: 0 0 4px 0;
    }
    &--small {
      padding: $card-content-top-padding $card-content-horizontal-padding $card-content-bottom-padding-small;
    }
    &--large {
      padding: $card-content-top-padding $card-content-horizontal-padding $card-content-bottom-padding-medium;
    }
  }
  &__nav,
  &__content {
    border-radius: $card-border-radius;
  }
  &__title,
  &__subtitle,
  &__manning-level {
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.2;
    letter-spacing: normal;
  }
  &__manning-level {
    margin-top: 10px;
    
    & .dropdown {
      display: inline-block;
    }
    & .btn {
      background: transparent;
      color: $card-title-color;
      border: solid 1px $grey-toggle-color !important;
      margin-right: 8px;
      margin-top: 1px;
      font-size: $font-size-sm;
      &:focus {
        // background: $toggle-background;
        box-shadow: none !important;
      }
      &.disabled {
        &:hover {
          cursor: not-allowed;
        }
      }
      &::after {
        margin-left: 0.5em;
      }
    }
    & .dropdown-menu {
      max-height: 230px;
      overflow-y: scroll;
      min-width: unset;
    }
    & .dropdown-item {
      font-size: $font-size-sm;
      padding: 0.25rem 1.25rem;
      &.active {
        background-color: #545b62;
      }
      &:focus {
        background: initial;
        color: initial;
      }
    }
  }
  &__title {
    color: $card-title-color;
    margin: 0 0 5px 0;
    &--link {
      color: $card-subtitle-color;
      &:hover {
        color: $card-title-color;
        text-decoration: none;
        cursor: pointer;
      }
    }
  }
  &__subtitle,
  &__manning-level {
    color: $card-subtitle-color;
  }
  &__subtitle {
    margin: 0;
    &.orange {
      color: $orange-1;
    }
  }
  &__content {
    background: $widget-bg;
    display: flex;
    flex-direction: column;
    height: 100%;
    min-height: 100%;
    padding: $card-content-top-padding $card-content-horizontal-padding $card-content-bottom-padding-large;
    position: relative;
    &.light-font {
      color: $light-font-color;
    }
    &-power-bi {
      padding: 0px;
      & > .edp-card__body {
        padding-bottom: 5px;
      }
    }
    &.disabled-style-text {
      color:#9b9b9b
    }
  }
  &__parent {
    background-color: $parent-card-bg-color;
    height: 100%;
  }
  &__parent-row {
    height: 100%;
  }
  &__body {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    &--start {
      margin-top: 20px;
    }
  }
  // make the report itself 100% height regardless (the parent i.e. &__body will control the height)
  &__body > .report,
  &__body-no-title > .report {
    display: flex;
    flex: 1;
    height: 100%;
  }
  &__full-screen {
    height: $detail-with-secondary-height;
    &--with-title {
      height: $full-screen-card-with-title-height;
      padding-top: 0;
      padding-bottom: 0;
    }
  }
  &__filters {
    margin-top: 15px;
  }
  &__body-disabled {
    pointer-events: none;
  }
  &__toggle-right {
    justify-content: flex-end !important;
    margin: auto;
  }
  &__toggle-container,
  &__title-container {
    display: inline-block;
  }
  &__toggle-container {
    padding: 0.2em 0.5em;
    > * {
      margin-bottom: 0.5em;
    }
  }
  &__toggles-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    margin-left: auto;
    flex-direction: column;
  }
  @media (min-width: $breakpoint-md) {
    &__toggles-container {
      flex-direction: row;
    }
  }
  &__flight-breakdown {
    padding: 25px 20px 15px;
    position: absolute;
    background-color: #313437f7;
    /* border: 1px solid #b1b1b1; */
    border-radius: 10px;
    top: 50%;
    bottom: 0;
    left: 50%;
    right: 0;
    transform: translate(-50%, -50%);
    z-index: 8;
    width: fit-content;
    height: fit-content;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: flex-start;
    align-items: flex-start;
    -webkit-flex-direction: column;
    flex-direction: column;
    box-shadow: 0 2px 6px 0 #313437;
    cursor: pointer;
    .close-btn {
      color: $card-title-color;
      position: absolute;
      top: 0;
      right: 0;
      padding: 5px;
      cursor: auto;
      &:hover {
        cursor: pointer;
      }
      svg {
        height: 12px;
      }
    }
    &-title {
      color: $card-title-color;
      font-weight: bold;
      display: block;
      margin-bottom: 5px;
    }
    &-subtitle {
      color: $card-title-color;
      font-weight: normal;
      margin-bottom: 10px;
      display: block;
      font-size: 0.85rem;
    }
    table {
      color: $card-title-color;
      th, td {
        padding: 8px;
        font-size: 0.7rem;
      }
    }
  }
  &__toggle {
    -ms-flex-align: center;
    -ms-flex-pack: center;
    align-items: center;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    text-align: center;
    width: 100%;
    height: 100%;
    
    &-dropdown {
      &-menu {
        min-width: $toggle-width;
        background-color: $toggle-background;
        border: 1px solid white;
        border-radius: $card-border-radius;
        color: white;
        padding: 0;
      }
      &-item {
        background-color: $toggle-background;
        color: white;
        height: $toggle-item-width;
      }
      &-item:hover {
        color: $gray-800 !important;
        border: 1px solid white;
        background-color: white !important;
      }
      &-toggle:hover,
      &-toggle:focus {
        outline: none;
        box-shadow: none !important;
      }
      &-toggle {
        border-color: $card-bg-lighter !important;
        border-radius: $card-border-radius !important;
        min-width: $toggle-width;
      }
    }
    & > ul {
      -ms-flex-direction: row;
      display: -ms-flexbox;
      display: flex;
      flex-direction: row;
      margin: 0;
      justify-content: flex-end;
      flex: 1;
    }
    &-li,
    &-dropdown-toggle,
    &-dropdown-item .dropdown-item {
      -ms-flex: auto;
      align-items: center;
      cursor: pointer;
      display: flex;
      flex: auto;
      font-size: 12px;
      font-stretch: normal;
      font-style: normal;
      height: 2.5em;
      // Font style
      justify-content: center;
      letter-spacing: normal;
      line-height: normal;
      
    }
    &-dropdown-toggle {
      width: $toggle-width;
    }
    &-li {
      padding: 0 16px;
      &:hover {
        background-color: #4a5159;
        color: #fff;
        font-weight: $font-weight-bold;
      }
    }
    &-li, 
    &-dropdown-toggle {
      border: 1px solid;
      border-color: $light-grey-2;
    }
    &-li:first-of-type {
      border-bottom-left-radius: 3px;
      border-top-left-radius: 3px;
    }
        
    &-li:last-of-type {
      border-bottom-right-radius: 3px;
      border-right: 1px solid;
      border-top-right-radius: 3px;
    }
    &-li[aria-disabled="true"] {
      border-color: #ccc !important;
      color: #ccc !important;
      cursor: not-allowed;
    }
    &-li.dark {
      background: $toggle-background;
      color: $light-grey-2;
    }
    &-li.dark.selected,
    &-dropdown-toggle,
    &-dropdown-toggle:hover,
    &-dropdown-toggle:focus {
      background-color: $light-grey-2;
      color: $gray-800;
      font-weight: $font-weight-bold;
    }
    &-li.grey-3 {
      color: $dark-font-color;
      border: solid 1px $dark-font-color;
    }
    &-li.grey-2,
    &-li.grey {
      color: $card-title-color;
      border: solid 1px $grey-toggle-color;
    }
    &-li.grey-3,
    &-li.grey-2,
    &-li.grey {
      border-right-width: 0;
      &:last-child {
        border-right-width: 1px;
      }
    }
    &-li.grey {
      background: transparent;
      &.selected {
        background-color: #fff;
        color: #393e44;
        font-weight: $font-weight-bold;
      }
    }
    &-li.grey-2 {
      background: #4a5159;
      &.selected {
        background-color: #222529;
        color: #fff;
        font-weight: $font-weight-bold;
      }
    }
    &-li.grey-3 {
      background: transparent;
      &.selected {
        background-color: #4a5159;
        color: #fff;
        font-weight: $font-weight-bold;
      }
    }
  }
  &__placeholder {
    padding: 0;
    align-items: center;
    overflow: hidden;
    &-image {
      border-radius: 1%;
      width: auto;
      position: absolute;
      margin: auto;
      top: 45%;
      vertical-align: middle;
    }
    &-header {
      position: absolute;
      color: grey;
      z-index: 5;
    }
  }
  &--no-chart {
    position: relative;
    display: flex;
    align-items: center;
    height: 100%;
    
    &.beontra,
    &.happyface {
      min-height: $minimum-chart-height;
    }
  }
  &__error-message {
    color: $card-title-color;
    margin: 0 auto;
    display: block;
    width: 21em;
    font-size: $font-size-sm;
    text-align: center;
    &--xs {
      font-size: $font-size-xs;
    }
  }
}
@media screen and (min-width: $breakpoint-xl) {
  .edp-card {
    // set the default height of the card
    &__default-height {
      height: $card-powerbi-default-height;
      min-height: $card-minimum-height-default;
    }
    &__title,
    &__subtitle {
      font-size: $card-title-font-size;
    }
    &__manning-level {
      font-size: $font-size-sm;
    }
    &__placeholder {
      &-image {
        max-width: 75%;
        max-height: 75%;
        left: calc(16% - 15px);
      }
    }
    &__header {
      &--space-between {
        justify-content: space-between;
      }
    }
  }
}
@media screen and (max-width: $breakpoint-xl) {
  .edp-card {
    &__default-height {
      height: $card-powerbi-default-height-taller;
      min-height: $card-minimum-height-default;
    }
    &__title,
    &__subtitle {
      font-size: $font-size-sm;
    }
    &__nav{
      font-size: $font-size-sm;
    }
    &__manning-level {
      font-size: $font-size-xs;
    }
    &__links {
      font-size: $font-size-xs;
    }
    &__placeholder {
      &-image {
        max-width: 50%;
        max-height: 50%;
        left: calc(28% - 15px);
      }
    }
    &--footer {
      color: $light-font-color;
      margin: 15px 0 0 0px;
      
      .icon img{
        margin-top: 0.3rem;
      }
    }
  }
}

@media screen and (max-width: $breakpoint-sm) {
  .edp-card {
    &__links {
      display: block;
      text-align: center;
      height: 100%;
      background: $card-bg;
      min-height: 60px;
    }
    &__nav {
      height: 100% !important;
      padding: 0 !important;
      text-align: center;
      .navbar-nav {
        display: block;
      }
    }
    &__items {
      padding: 15px;
    }
    &__nav-item {
      margin: 0;
      padding: 10px;
      height: auto;
      width: 100%;
      &.active {
        border-bottom: none;
        background-color: $brand-warning;
        color: #000;
        border-radius: 5px;
      }
    }
  }
}
