#root {
  .app {
    min-width: 320px; // iPhone 5 width
    width: 100%;
  }
}

// Landing page styling
.edp-landing {
  background: $light-background-color;
  color: $dark-font-color;
  min-height: $landing-page-height;
  &__column {
    min-height: $landing-page-height;
  }

  // text styling
  &__description {
    margin: auto;
    padding-bottom: $content-padding-vertical;
    padding-top: $content-padding-vertical;
    width: 57%;
  }

  &__title {
    font-size: $font-size-xl;
    line-height: 1.25;
  }

  &__subtitle {
    font-size: $font-size-md;
    line-height: 1.2;
  }

  &__text {
    font-size: $font-size-sm;
    line-height: 1.6;
  }

  &__text, &__subtitle, &__title {
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
  }

  // image styling
  &__image {
    height: 65vh;
    margin-left: auto;
    max-height: 54vw;
    max-width: 100%;
    min-height: 400px;
  }

  &__image-background {
    align-items: center;
    background-image: url('../assets/img/landing-page-graphic-wallpaper.svg');
    background-position: left center;
    background-repeat: no-repeat;
    background-size: 100vh 100vh;
    display: flex;
    height: 100%;
  }

  &__image-container {
    background: #d8d8d8;
    padding: 0 !important;
  }

  // footer styling
  &__footer {
    display: table !important;
    height: $footer-height;
    width: 100%;
    a:hover {
      color: #fff;
      text-decoration: none;
    }
  }

  &__footer-content {
    color: $light-font-color;
    display: table-cell;
    font-size: 13px;
    vertical-align: middle;
  }
}

// increase height of footer on much smaller screens 
@media (max-width: $breakpoint-footer-width) {
  .edp-landing  {
    &__footer-navbar {
      height: 72px;
    }
  }
}

// increase width of description container on tablets and mobile
@media (max-width: $breakpoint-xl) {
  .edp-landing  {
    &__description {
      width: 88%;
    }
  }
}

@media (min-width: $breakpoint-lg) {
  .edp-landing  {
    &__description {
      width: 70%;
    }
  }
}

// adjust height of description container on smaller devices (prevents text overlay on navbar)
@media (max-height: $breakpoint-height-mobile) {
  .edp-landing  {
    &__description {
      min-height: $landing-page-height;
    }
  }
}

@media (min-height: $breakpoint-height-mobile) and (min-width: 450px) {
  .edp-landing  {
    &__description {
      height: $landing-page-height;
    }
  }
}

@media (max-width: 350px) {
  .edp-landing  {
    &__subtitle {
      margin-top: 25px;
    }

    &__title {
      margin-bottom: 40px !important;
    }
  }
}


// Button styling
.gold-button {
  background-color: $brand-warning;
  border-color: $brand-warning;
}
// this should be updated to `edp-button` as in feature/1160-feedback-form branch
// reused in multiple places
.edp-button {
  border: 0;
  font-size: 20px !important;
  font-stretch: normal;
  font-style: normal;
  font-weight: bold;
  letter-spacing: normal;
  line-height: 1.2;
  padding: 15px;
  text-align: center;
  width: 7.5em;
}

// remove blue border on focus
.edp-button:focus {
  outline: 0;
}

.edp-button:active {
  border: 0;
}

.landing-page-title {
  font-weight: $font-weight-bold;
  padding-bottom: 20px;
}

.centered-row {
  display: table-cell;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
  vertical-align: middle;
}

// no longer relevant
.sample-card-image {
  width: 450px;
}

.gold-button,
#update-me-btn {
  color: $dark-font-color;
}

#update-me-btn {
  background-color: $brand-secondary;
  border-color: $brand-secondary;
}

#update-me-btn:hover {
  text-decoration: none;
}
