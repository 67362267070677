.dhhs-badge {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    margin: 0 auto;

    // Modifiers
    &--green {
        background-color: $brand-success;
    }
    &--amber {
        background-color: $amber-alert-color;
    }
    &--red {
        background-color: $red-alert-color;
    }
}

.dhhs-table {
    text-align: center;
    color: white;
    td {
        border-right: 1px solid #d8d8d8;
        // width: calc(100%/7);
        min-width: 40px;
        vertical-align: middle;
        height: 150px;
        padding: 5px;
    }
    td:last-child {
        border-right: 0;
    }
}


@media screen and (max-width: $breakpoint-md) and (min-width: $breakpoint-sm) {
    .dhhs-table td {
        width: auto;
    }
}
@media screen and (max-width: $breakpoint-lg) and (min-width: $breakpoint-md) {
    .grid-card-container--3 {
        grid-template-columns: repeat(2, minmax(15.1em, 1fr));
        grid-template-rows: repeat(2, 1fr);
    }
}
@media screen and (max-width: $breakpoint-sm) {
    .grid-card-container--3 {
        grid-template-columns: 1fr;
        grid-template-rows: repeat(3, 1fr);
    }
}