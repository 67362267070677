.edp-card {
    &__body {
      &--weather-card-body {

        justify-content:unset;

        .table {
            margin-bottom: 0px;
            height: 100%;
            min-height: 100%;

            thead th {
                padding: 0;
            }

            tbody th {
                padding: 0;
                vertical-align: middle;
            }

            tbody td {
                padding: 0;
            }

            tbody tr:last-child th, 
            tbody tr:last-child td {
                padding-bottom: 0;
            }
        }


        
        .windgust-value, .windspeed-value, .visibility-value  {
            display: block;
            font-size:$font-size-md;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: $font-size-base;
            letter-spacing: normal;
            margin: auto;
            width: 3rem;
        }

        .windgust-unit, .windspeed-unit, .visibility-unit  {
            display: block;
            font-size:$font-size-weather-table-row-subtext;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: $line-height-weather-card-sub-unit;
            letter-spacing: normal;
            text-align: center;
            opacity: 0.7;

        }

        .status-red {
            border-left: 0.2rem solid $red-alert-color;
            content: " ";
            padding-left: 0px;
        }
        .status-amber {
            border-left: 0.2rem solid $amber-alert-color;
            content: " ";
            padding-left: 0px;
        }
        .status-green {
            border-left: 0.2rem solid $green-alert-color;
            content: " ";
            padding-left: 0px;
        }

        .temperature {
            font-size: $font-size-weather-table-row-text;
            font-family: $font-family-base;
        }

        .weather-icon-main {
            width: 2.4rem;
        }

        .weather-icons-row {
            float: left;
        }

        @media screen and (min-width: $breakpoint-xl) {
            .table {

                thead th {
                    padding: 0.5rem 0 0 calc(1vw);
                    font-family: $font-family-base;
                    font-size: $font-size-weather-table-row-text;
                    font-stretch: normal;
                    font-style: normal;
                    font-weight: normal;
                }

                tbody td{
                    padding: calc(2vh) 0 0 calc(1vw);
                }

                .windgust-value, .windspeed-value, .visibility-value  {
                    font-size: calc(1.8vw);
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    width: calc(4vw);
                    line-height:calc(2.8vh);
                    letter-spacing: normal;
                }

                .status-red {
                    border-left: 0.3rem solid $red-alert-color;
                }
                .status-amber {
                    border-left: 0.3rem solid $amber-alert-color;
                }
                .status-green {
                    border-left: 0.3rem solid $green-alert-color;
                }
            }

            .weather-card {
                .weather-card-toggle-unit {
                    margin: inherit;
                    padding-right: 2rem;
                }
            }
        }
        @media screen and (max-width: $breakpoint-xl) {
            .table {
                tbody td {
                    padding: 10px 0 0 0;
                }

                img.weathericon-top {
                    width: calc(3.5vw)
                }
            }

        }
      }
    }
  }


  .weather-card {
      .btn-group{
          .btn-secondary {
            width: 42px;
            height: 16px;
            border-radius: 4px;
            border: solid 0.5px #c3c5c7;
            background-color: #393e44;
            font-family: $font-family-base;
            font-size: $font-size-weather-card-toggle;
            font-stretch: normal;
            font-style: normal;
            line-height: $font-size-base / 2;
            letter-spacing: normal;
            text-align: center;
            color: $light-font-color;
            opacity: 0.7;
          }

          .btn-secondary.active {
              color:$dark-font-color;
              border: solid 1px #ffffff;
              background-color: $light-background-color;
              font-weight: $font-weight-bold;
              opacity: unset;
          }
          .btn:focus, .btn.focus{
              box-shadow: none !important;
          }
          
      }

      .weather-card-toggle-unit {
          text-align: right;
          padding-left: 0;
      }
  }


  