$item-height: 2.5em;
$dot-size: 50px;
$small-dot-size: 35px;
$large-dot-size: 50px;
$graph-card-title-width: 295px;

.edp-passenger-flow {
  min-height: $detail-with-secondary-height;
  padding: 12px $content-padding-horizontal;
  margin: 0 7.5px;

  &__subtitle {
    color: white;
  }
  &__subtitle {
    text-align: right;
    float: right;
    font-size: $font-size-base;
    &.historical {
      color: $orange-1;
    }
  }
  &__col {
    padding: 0 7.5px !important;
  }
  &__data-title {
    display: flex;
    align-items: center;
    &--centered {
      margin: 0 auto;
    }
  }
  &__data-field {
    text-align: center;
    font-size: $font-size-xxxl;
    &.emphasized {
      color: white;
    }
    &-border {
      border-bottom: 1px solid $card-title-color;
    }
    &--title {
      text-align: center;
      font-size: $font-size-sm;
    }
  }
  &__zone-title {
    text-align: left;
    padding-left: 0 !important;
  }
  &__mobile-dot {
    height: 12px;
    width: 12px;
    border-radius: 12px;
    display: none;
    margin-left: 5px;
  }

  &__date-shown {
    margin-left: 7px;
    font-weight: $font-weight-bold;
  }

  &__title {
    margin-top: 12px;
  }
  
  &__title,
  &__subtitle {
    display: inline-block;
  }
  &__last-updated,
  &__connecting {
    color: $card-title-color;
  }
  &__connecting {
    line-height: 25px;
    padding-top: 33px;
  }
  &__date-displaying {
    min-width: 290px;
  }

  &__final-row {
    margin-top: 15px;
  }

  &__board {
    background: $card-bg-lighter;
    box-shadow: $card-box-shadow;
    color: $card-title-color;
    border-radius: $card-border-radius;
    padding: $card-content-top-padding $card-content-horizontal-padding $card-content-bottom-padding-large;
    height: 100%;

    &-header {
      padding-bottom: $card-content-bottom-padding-large;
    }
    &-content {
      height: calc(100% - 75px);
      & > .col > .row:last-child {
        margin-bottom: 25px;
      }
    }
    &-title {
      margin: 0;
      display: inline-block;
      color: $card-title-color;
      &--content {
        font-size: $font-size-md;
        margin-bottom: 5px;
      }
    }

  }

  &__datepicker {
    &-button {
      margin-left: 15px;
      border-radius: $card-border-radius;
      padding: 4px 16px;
      color: $gray-900;
      background: $light-grey-2;
      font-size: 14px;
      font-weight: bold;
  
      &-icon {
        padding-left: 3px;
        color: $gray-900;
  
        height: 14px;
        margin-top: -2px;
        width: 22px;
      }
      &:hover {
        cursor: pointer;
      }
      &:focus {
        outline: none;
      }
    }
    &-container {
      float: right;
      & > div {
        height: 31px;
      }
    }
  }

  &__graph-toggle {
    display: inline-block !important;
    width: initial !important;
    &-container {
      display: inline-block;
      vertical-align: middle;
      margin-top: -25px;
      &.centered {
        width: calc(100% - 2 * #{$graph-card-title-width});
        text-align: center;
      }
      &.right {
        width: $graph-card-title-width;
        text-align: right;
      }
    }
  }
  &__graph {
    display: inline-block;
    width: 100%;
    height: 100%;
  }
  &__graph-card-title-container {
    width: $graph-card-title-width;
  }
  
  &__icon {
    &-gates {
      margin-bottom: -1px;
    }
    &-immigration {
      margin-bottom: -1px;
      margin-top: -1px;
    }
    &-baggage {
      margin-top: -4px;
    }
  }
}

.edp-peak-card {
  &__title {
    font-size: $font-size-sm;
    &--container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    &.centered {
      margin: 0 auto;
    }
    &.emphasized {
      color: $light-font-color;
      font-weight: $font-weight-bold;
    }
  }
  &__data-col {
    padding-top: 15px;
    text-align: center;
    padding-right: 0 !important;
    &--bg {
      background-color: $dark-grey-1;
    }
  }
  &__title-col {
    padding: 0 !important;
  }
  &__data-container {
    border-left: 0.5px solid white;
    border-right: 0.5px solid white;
    padding-right: 15px;
    &--row {
      padding: 0 15px;
    }
  }
  &__data-field {
    padding-top: 22px;
    &:last-child {
      padding-bottom: 22px;
    }
  }
  &__transit-time-field {
    font-size: $font-size-xxl;
    line-height: 50px;
    color: $card-subtitle-color;
  }
  &__indicator {
    width: 25px;
    height: 5px;
    margin: 0 auto;
  }
  &__header-item {
    width: 50%;
    display: inline-block;
    &.left-align {
      text-align: left;
    }
    &.right-align {
      text-align: right;
    }
  }
  &__small-title {
    font-size: $font-size-xs;
    &.at-time {
      padding-top: 5px;
    }
    &.between-time {
      padding-bottom: 5px;
    }
  }
}

// styling for mobile-sized screens
$live-board-breakpoint: $breakpoint-sm - 1;
@media screen and (max-width: $live-board-breakpoint) {
  .edp-passenger-flow {
    &__zone-title {
      text-align: center;
      font-size: $font-size-md;
      padding: 15px;
    }
    &__data-field--footer {
      display: none;
    }

    &__icon {
      &-gates,
      &-immigration,
      &-baggage {
        display: none;
      }
    }
    &__mobile-dot {
      display: inline-block;
    }
  }
  .edp-peak-card {
    &__title {
      font-size: $font-size-index;
      &--container {
        transform: rotate(-90deg);
      }
    }
    &__body {
      padding-bottom: 26px;
      padding-right: 10px;
    }
    &__transit-time-field {
      font-size: $font-size-lg;
    }
    &__small-title {
      font-size: $font-size-index;
    }
    &__indicator {
      width: 15px;
      height: 4px;
    }
  }
}

// overwrite react datepicker triangle positioning
.react-datepicker {
  &__triangle {
    left: 224px !important;
  }
}

@media screen and (max-width: $breakpoint-xl) {
  .edp-passenger-flow {
    &__col {
      margin-bottom: 15px;
    }
    &__final-row {
      margin-top: 0;
    }

    &__graph-toggle {
      &-container {
        margin-top: 15px;
        &.centered,
        &.right {
          width: 100%;
        }
        &.right {
          text-align: center;
        }
      }
    }
  }
}

.center-text {
  text-align: center;
}

.fix-bounds {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.inner-col {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.inner-col.de-emphasized {
  color: #B3B3B3;
}

.react-datepicker-wrapper, .react-datepicker__input-container {
  display: block;
}
.react-datepicker-triangle {
  display: none;
}