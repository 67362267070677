.edp-custom-modal.default {
    .modal-footer,
    .modal-body {
        background-color: $light-background-color;
    }

    .modal-body {
        padding-bottom: 0 !important;
        padding-left: 30px !important;
        padding-right: 30px !important;
        padding-top: 25px !important;
    }

    .modal-header > .modal-title {
        font-weight: $font-weight-bold;
    }

    .modal-header,
    .modal-footer {
        border: 0;
    }

    .modal-footer {
        justify-content: flex-start !important;
        margin: 0;
    }

    .modal-footer > button {
        display: block;
        margin: 5px auto;
    }
}