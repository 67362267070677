$tooltip-background-color: rgba(45, 49, 54, 0.95); // $gray-900 with 0.95 opacity
$tooltip-text-color: $light-font-color;

.edp-tooltip {
    &__icon {
        margin-bottom: 2px;
        height: 14px;
        width: 14px;
        fill: $card-title-color;
        &:hover {
            cursor: pointer;
        }
    }
    &__anchor {
        height: 14px;
        width: 14px;
        font-size: 100%;
        background: transparent;
        font-family: inherit;
        border: 0;
        padding: 0;
        &:focus {
            outline: none;
        }
    }
    &__content {
        box-shadow: $card-box-shadow;
        padding: 25px 30px;
        max-width: 500px;
        z-index: 2000; // Override the Bootstrap z-index
    }
    &__title {
        font-size: $font-size-base;
        margin: 0;
    }
    &__description {
        font-size: $font-size-xs;
        line-height: 1rem;
    }
    &__logo {
        width: 120px;
    }

    &__table {
        margin: 0 auto;
        border-radius: $card-border-radius;
        box-shadow: $card-box-shadow;
        
        td {
            color: $tooltip-text-color;
        }
        th, td {
            padding: 10px 22px;
            border-collapse: separate;
            text-align: center;
            font-size: $font-size-xs;
        }
        th {
            background: $parent-card-bg-color;
            color: $tooltip-text-color;
            text-transform: uppercase;
            &:first-child {
                border-top-left-radius: $card-border-radius;
            }
            &:last-child {
                border-top-right-radius: $card-border-radius;
            }
        }
    }

    &__separator {
        margin-top: 0.5rem;
        margin-bottom: 1rem;
        border-color: #D3BB8D;
        width: 40%;
        display: block;
        border-width: 2px;
        margin-right: 60%;
        float: left;
    }
}

.edp-tooltip-modal {
    color: $tooltip-text-color;
    .modal-content {
        background-color: $tooltip-background-color;
        padding: 1em;
    }
    &__button {
        fill: $tooltip-text-color;
        margin-left: 10px; // avoid the content of the modal from touching the close 'X'
    }
}

// overwrite the 0.9 opacity and background color on tooltip
.__react_component_tooltip {
    &.show {
        background-color: $tooltip-background-color;
        opacity: 1 !important;
    }

    // adjust color of triangle
    &.type-dark.place-left::after {
        border-left-color: $tooltip-background-color;
    }
    &.type-dark.place-right::after {
        border-right-color: $tooltip-background-color !important;
    }
    &.type-dark.place-top::after {
        border-top-color: $tooltip-background-color;
    }
    &.type-dark.place-bottom::after {
        border-bottom-color: $tooltip-background-color;
    }
}

.edp__tooltip {
    
    &--content {
        // added content class just to fix the issue in z-indexing on tooltip and labels in y-axis
        // background-color: rgba(52, 52, 52, 1);
        // height: fit-content;
        // width: fit-content;
        // position: absolute;
        // top: -8px;
        // left: -7px;
        // z-index: 9;
        // padding: 8px;
    }
    &--bullet {
        height: 10px;
        width: 10px;
        border-radius: 10px;
        display: inline-block;
        margin-right: 5px;
    }
    &--line {
        height: 4px;
        width: 15px;
        margin-bottom: 2px;
        display: inline-block;
        margin-right: 5px;
    }
    &--text {
        color: #CCC;
        padding: 3px 6px 0;
        margin-bottom: 5px;
        font-size: 13px;
        display: flex;
        justify-content: space-between;
        width: 200px;
    }
    &--total {
        margin-left: 20px;
    }
}
