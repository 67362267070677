$happyface-device-number-width: 36px;
$happyface-cell-padding: 12px;
$happyface-border-color: #9b9ea1;
$happyface-map-btn-spacing: 8px;
$happyface-map-btn-bg: #26282d;
$happyface-map-btn-color: #fff;
$happyface-vertical-padding: 0.75em;

.edp-happyface {
  font-size: 13px;
  display: block;

  height: 100%;
  display: block;
  overflow: hidden;

  // Location title
  &__location {
    padding-bottom: $happyface-vertical-padding;
    font-size: $font-size-base;
    font-weight: $font-weight-bold;
    text-align: center;
  }

  // Map
  &__map {
    position: relative;
    width: 100%;
    height: 350px;
    .esri-widget {
      background: none;
      &--button {
        background-color: $happyface-map-btn-bg;
        color: $happyface-map-btn-color;
        opacity: 0.9;
        width: 25px;
        height: 25px;
        margin: $happyface-map-btn-spacing;
      }
      &:focus {
        outline: none;
      }
    }

    .esri-view .esri-view-surface {
      &:focus {
        outline: none;
      }
    }

    .esri-ui-bottom-right {
      flex-flow: column;
    }

    .esri-ui-corner .esri-component {
      box-shadow: none;
      &:focus {
        outline: none;
      }
    }
    .esri-icon-plus,
    .esri-icon-minus {
      font-size: $font-size-base;
      &:before {
        position: relative;
        top: -3px;
      }
    }
    .esri-icon-plus:before {
      content: "+";
    }
    .esri-icon-minus:before {
      content: "-";
    }

    &__btn {
      background-color: $happyface-map-btn-bg;
      color: $happyface-map-btn-color;
      opacity: 0.9;
      border: 0 none;
    }
    &__btn--zoom {
      font-size: $navbar-initials-font-size;
      padding: 5px 10px;
      margin: 16px $happyface-map-btn-spacing;
    }

    &__switch-floors {
      margin-top: 1rem;
    }
  }

  // Table
  &__table {
    overflow-y: scroll;
    display: table;
    margin-top: 0;
    margin-bottom: 0;
    height: 100%;

    &-container {
      padding-bottom: 50px;
      scroll-behavior: smooth;
      overflow: auto;
      margin-top: calc(1rem + 3rem); // add 3rem because the toggle is pushed below the map insidev <Map />
      max-height: 50%;
    }

    thead th {
      font-weight: $font-weight-normal;
      border-top-width: 0;
      border-bottom-width: 0;
      z-index: 1;
      &.add-border {
        box-shadow: inset 0 -1px 0 #fff;
      }
      &.stick-to-top {
        position: sticky;
        background: $widget-bg;
        top: 0;
      }
    }

    th,
    td {
      color: rgba(255, 255, 255, 0.7);
    }
    tbody td {
      font-size: 14px;
    }

    tbody + tbody {
      border-bottom: solid 1px $happyface-border-color;
      border-top-width: 0;
    }

    .device-number {
      width: $happyface-device-number-width;
    }
    .device-name {
      width: 30%;
    }
    .device-toggle {
      text-align: right;
      .is-expanded svg {
        transform: rotate(180deg);
      }
    }

    .is-active &__primary-row {
      font-weight: $font-weight-bold;
      td {
        color: #fff;
      }
    }

    .is-highlighted &__primary-row {
      background-color: #fff;
      font-weight: bold !important;
      td, th {
        color: #393e44;
      }
    }

    &__primary-row {
      cursor: pointer;
    }
  }

  // Table: subtable
  &__subtable-container {
    > td {
      padding: 0;
      border: 0 none;
    }
  }
  &__subtable {
    thead th,
    tbody td {
      border: 0 none;
      font-size: 12px;
    }
    th {
      background-color: #020202;
      padding-top: 5px;
      padding-bottom: 5px;
    }
    td {
      background-color: #4a5159;
      padding-top: 0;
      padding-bottom: 0;
    }
    tbody tr:first-child td {
      padding-top: 10px;
    }
    tbody tr:last-child td {
      padding-bottom: 10px;
    }

    .response-number {
      padding-left: $happyface-device-number-width + $happyface-cell-padding;
      width: 20%;
    }
    .response-time {
      width: 15%;
    }
  }
}

.edp-happyface-container {
  max-height: 850px;
  & > div > .edp-card__header {
    padding-bottom: $happyface-vertical-padding;
  }
  // Card footer
  .edp-card--footer {
    font-size: $navbar-initials-font-size;
    margin-bottom: 0;
  }
  &--left {
    .edp-happyface__location {
      text-align: left;
    }
  }
}
@media screen and (min-width: $breakpoint-lg) {
  .edp-happyface-container {
    height: 68vw !important;
  }
}
@media screen and (max-width: $breakpoint-lg)  {
  .edp-happyface-container {
    height: 55em !important;
  }
}
@media screen and (max-width: $breakpoint-sm) {
  .edp-happyface__map {
    padding: 0 30px;
  }
}