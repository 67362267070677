// StatusIndicator
.status {
    display: inline-block;
    font-size: $font-size-md;
    color: $light-font-color;
    padding: 0 0.1em 0.15em;
    height: max-content;

    // Modifiers
    &--green {
        border-bottom-color: $green-alert-color;
    }
    &--amber {
        border-bottom-color: $amber-alert-color;
    }
    &--red {
        border-bottom-color: $green-alert-color;
    }

    &.code {
        color: $card-title-color;
    }
}
// Number
.number {
    color: $light-font-color;
    line-height: 1;
    &--small {
        font-size: $font-size-md;
    }
    &--medium {
        font-size: $font-size-lg;
    }
    &--large {
        font-size: $font-size-xl;
    }
    &--x-large {
        font-size: $font-size-xxl;
    }
    &--xx-large {
        font-size: $font-size-xxxl;
    }
}
.status + .number {
    margin-left: 0.6em;
}
// QueueTimes
.queue-times {
    .edp-card--no-chart {
        min-height: $queue-times-height;
        margin-top: 16px;
    }

    .edp-card--no-chart img{
        top: 9vh;
    }

}
.header {
    display: flex;
    flex-grow: 1;
    align-items: flex-start;
}
.push-right {
    margin-left: auto;
}
.grid-card-container--3 {
    display: grid;
    grid-template-columns: repeat(3, minmax(11em, 1fr));
    grid-template-rows: auto;
    grid-gap: 4em;
    grid-auto-flow: column;
}

@media screen and (max-width: $breakpoint-md) and (min-width: $breakpoint-sm) {
    .grid-card-container--3 {
        grid-template-columns: 1fr;
        grid-template-rows: repeat(3, 1fr);
    }
}
@media screen and (max-width: $breakpoint-lg) and (min-width: $breakpoint-md) {
    .grid-card-container--3 {
        grid-template-columns: repeat(2, minmax(15.1em, 1fr));
        grid-template-rows: repeat(2, 1fr);
    }
}
@media screen and (max-width: $breakpoint-sm) {
    .grid-card-container--3 {
        grid-template-columns: 1fr;
        grid-template-rows: repeat(3, 1fr);
    }
}