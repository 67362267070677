
 
.sticky-nav {
    display: flex;
    position: sticky;
    top: 40px;
    opacity: 1;
    z-index: 9;
    &-enter-active {
        display: flex !important;
        position: sticky;
        top: 0px;
        opacity: 0;
        z-index: 9;
    }
    &-enter-done {
        display: flex !important;
        position: sticky;
        top: 40px;
        opacity: 1;
        z-index: 9;
    }
}

