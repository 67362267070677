// set the height specifically for some cards
.edp-ioc {
  // this prevents the row from being very condensed prior to cards loading
  &__first-row {
    min-height: 525px;
  }
  &__weather-widget-card {
    min-height: 300px;
  }
  &__overview-terminals {
    height: 400px !important;
  }
}

@media screen and (min-width: $breakpoint-xl) {
  .edp-ioc {
    &__overview-terminals {
      height: 33vw !important;
    }
    &__first-row {
      height: 26vw !important;
    }
  }
  .edp-card.edp-ioc__parking-card > .edp-card__content > .edp-card__body {
    height: 6.8vw;
  }
}

@media screen and (max-width: $breakpoint-xl) {
  .edp-card.edp-ioc__parking-card > .edp-card__content > .edp-card__body {
    height: 5em;
  }
}

.edp-ioc {
  &__center-col {
    height: 100%;
  }
}


@media screen and (min-width: $breakpoint-md) and (max-width: $breakpoint-xl) {
  .edp-ioc {
    &__ams-card {
      height: 122vw !important
    }
  }
}

@media screen and (max-width: $breakpoint-md) {
  .edp-ioc {
    &__ams-card {
      height: 122vw !important;
    }
  }
}

@media screen and (max-width: $breakpoint-sm) {
  .edp-ioc {
    &__taller-card {
      height: 59vw !important;
    }
  }
}

@media screen and (min-width: $breakpoint-sm) {
  .edp-ioc {
    &__taller-card {
      height: 33vw !important;
    }
  }
}

// `IOC - Overview` board custom breakpoints for custom card layout
$one-card-breakpoint: calc(#{$reduced-card-layout-width} * 1.15);
$two-card-breakpoint: calc(#{$one-card-breakpoint} * 2);
$three-card-breakpoint: calc(#{$one-card-breakpoint} * 3);

@media screen and (max-width: $breakpoint-xl) and (min-width: $three-card-breakpoint) {
  .edp-ioc {
    &__board {
      width: 60em;
      margin: 0 auto;
    }
  }
}
@media screen and (max-width: $three-card-breakpoint) and (min-width: $breakpoint-md) {
  .edp-ioc {
    &__board {
      width: 41em;
      margin: 0 auto;
    }
  }
}
@media screen and (max-width: $breakpoint-md) and (min-width: $two-card-breakpoint) {
  .edp-ioc {
    &__board {
      width: 38.5em;
      margin: 0 auto;
    }
  }
}
@media screen and (max-width: $two-card-breakpoint) {
  .edp-ioc {
    &__board {
      width: 19.5em;
      margin: 0 auto;
    }
  }
}