$board-padding: 30px;

.edp-navbar-sm {
  background-color: $card-bg;
  padding: 0 !important;
  width: 100%;
  position: sticky;
  top: 40px;
  z-index: 9;
  .container-fluid {
    padding: 0;
  }
  .edp-navbar__burger-icon {
    text-align: right;
    width: 100%;
    margin: 0;
    padding: 10px;
    box-shadow: none !important;
  }
  .dropdown-toggle {
    color: #fff;
    border: 1px solid #fff !important;
  }
  
  &__collapse {
    position: relative;
    z-index: 999;
    width: 100%;
    background-color: $card-bg;
  }
  .edp-card__toggle ul {
    justify-content: center;
  }
}

.edp-board {
  background-clip: content-box;

  &__datefilter {
    background-color: $card-bg-lighter;
    border-radius: 3px;
    // height: 62px;
    width: 100%;
    padding: 17px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    
    ul {
      justify-content: flex-end;
    }
    &--dark {
      background-color: $card-bg;
    }

    &--fit-content {
      .edp-card__toggle-li {
        padding: 0;
      }
    }
  }
  &__background {
    border-radius: $card-border-radius;
  }
  &__row {
    padding-top: 0 !important;
  }

  &__col {
    padding-bottom: $card-padding;
  }

  &__indev {
    height: 200px;
  }

  &__header-row {
    padding-bottom: 0 !important;
  }

  &__title {
    color: $light-font-color;
    margin: 25px 15px;
    font-size: $font-size-lg;
    &--large {
      margin: 0;
      color: $light-font-color;
      font-size: $font-size-xl;
      line-height: 1;
    }
  }

  &__legend {
    color: #B6B8BA;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: auto;
  }

  & > .row {
    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      padding-bottom: 35px;
    }
  }

  // if no-tail class exists on a board, do not add extra padding at the bottom of the board AND negate the card padding
  & > .no-tail {
    &:last-child {
      padding-bottom: 0;
    }
    margin-bottom: -25px;
  }
}

.default-board-padding,
.minimum-board-padding { 
  transition: 0.4s all ease;
}

// $reduced-breakpoint: 950px;
@media only screen and (min-width: $breakpoint-xl) {
  .edp-board {
    &__margin-align-nav {
      padding: 15px 162px !important;
    }
  }

}
// adjust for smaller devices (mobile and tablet)
@media only screen and (max-width: $breakpoint-xl) {
  .edp-board { 
    &__margin-align-nav {
      padding: $inner-card-padding 5% !important;
    }
  }
}

@media only screen and (max-width: $breakpoint-md) {
  .edp-board {
    &__col {
      padding-bottom: $card-padding;
    }
  }
}


// nav side bar styling
@media screen and (min-width: $breakpoint-xl) {
  .default-board-padding {
    padding: $board-vertical-padding $board-horizontal-padding;
    &.navbar-open {
      padding-left: calc(1.75 * #{$board-horizontal-padding});
      padding-right: calc(0.25 * #{$board-horizontal-padding});
    }
  }
  .minimum-board-padding {
    padding: 0 calc(#{$nav-side-bar-width} / 2);
    &.navbar-open {
      padding-left: calc(#{$nav-side-bar-width});
      padding-right: 0;
    }
  }
}
@media screen and  (min-width: $breakpoint-md) and (max-width: $breakpoint-xl) {
  .default-board-padding {
    padding: $board-vertical-padding $board-horizontal-padding/2;
  }
}
@media screen and (max-width: $breakpoint-md) {
  .default-board-padding {
      padding: $board-vertical-padding $board-horizontal-padding/4;
    }
}