.edp-help-centre {
    background: #d8d8d8;
    color: #353735;
    min-height: calc(100vh - #{$navbar-height});

    &__title {
       color: $light-font-color;
       margin-left: 15px;
    }

    &__nav-column {
        min-width: 13em;
    }

    &__container {
        max-width: 50em;
        padding: 60px $content-padding-horizontal;

        &--nav {
            padding-bottom: 0;
        }

        & img {
            max-height: 30em;
            max-width: 100%;
        }
    }
    &__markdown {
        & > h1 {
            font-size: $font-size-xl;
            margin-bottom: 30px;
        }
        & > h2 {
            font-size: $font-size-lg;
            font-weight: $font-weight-bold;
        }
        & > h3 {
            font-size: $font-size-md;
        }
        & > p {
            padding: 5px 0;
        }
        & > ul {
            margin-left: 40px;
            & > li {
                padding: 5px;
            }
            // sub-lists
            li ul {
                padding-left: 40px;
                padding-top: 7px;
                padding-bottom: 7px;
            }
        }
    }
    &__page {
        &-name {
            padding-left: 12px;
            opacity: 0.5;
            &.active {
                opacity: 1.0;
                padding-left: 8px;
                border-left: 4px solid $edp-brand-purple;
            }
            &:hover {
                cursor: pointer;
            }
        }
    }
    &__dropdown {
        padding-bottom: 15px;

        &-toggle {
            font-weight: $font-weight-bold !important;
            color: $dark-font-color !important;
            background-color: transparent !important;
            border: none !important;

            &::after {
                margin-left: 10px !important;
            }
            &:focus {
                box-shadow: none !important;
            }
            &:hover {
                background: $gray-400 !important;
            }
        }
        &-item {
            color: $dark-font-color;
            min-width: 14em;
        }
    }
}