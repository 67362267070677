$blur-amount: 7px;

@-webkit-keyframes rotate-infinite {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100%  {
    -webkit-transform: rotate(360deg); 
  }
}

@keyframes rotate-infinite {
  0% {
    transform: rotateZ(0deg);
  }

  100%  {
    transform: rotateZ(360deg); 
  }
}

.loading-spinner {
  -webkit-animation: rotate-infinite 1.4s linear infinite;
  animation: rotate-infinite 1.4s linear infinite;
  border-radius: 50%;
  display: inline-block;
  &__light {
    border: 2px solid #fff;
    border-right-color: transparent;
  }

  &__dark {
    border: 2px solid $dark-background-color;
    border-right-color: transparent;
  }

  &__normal-size {
    height: 90px;
    width: 90px;
  }

  &__mini-size {
    height: 45px;
    width: 45px;
  }
}

.loading-blur {
  filter: blur($blur-amount);
  -o-filter: blur($blur-amount);
  -webkit-filter: blur($blur-amount);
  -ms-filter: blur($blur-amount);
  filter: url("data:image/svg+xml;utf9,<svg%20version='1.1'%20xmlns='http://www.w3.org/2000/svg'><filter%20id='blur'><feGaussianBlur%20stdDeviation='3'%20/></filter></svg>#blur");
  filter:progid:DXImageTransform.Microsoft.Blur(PixelRadius='7');
  pointer-events: none;
}
