.edp-card {
  &__body {
    &--travel-time-to-airport {
      @media screen and (min-width: $breakpoint-xl) {
        padding: 0 10%;
      }
      @media screen and (max-width: $breakpoint-xl) {
        padding: 1em 7%;
      }
    }
  }
}

.arterial-road-status {
    background-color: $arterial-road-row-color;
    display: flex;
    justify-content: space-between;
    padding-left: 16px;
    margin: 0 2% 15%;
    &:last-child {
      margin-bottom: 0;
    }
    position: relative;

    &__title-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-right: 2em;
    }
  
    &__code,
    &__name {
        margin: 0;
        padding: 0;
        line-height: 1;
    }

    &__travel-time {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      line-height: 1;
    }

    &__code {
      color: $card-title-color;
      margin-bottom: 8px;
    }
  
    // color indicator on the left
    &:before {
      border-left: calc(2.5px + 0.11vw) solid $card-bg;
      position: absolute;
      height: 80%;
      top: 8%;
      bottom: 0;
      left: 0;
    }
  
    &.red {
      &:before {
        content: "";
        border-left-color: $red-alert-color;
      }
    }

    &.amber {
        &:before {
          content: "";
          border-left-color: $amber-alert-color;
        }
      }
  
    &.green {
      &:before {
        content: "";
        border-left-color: $green-alert-color;
      }
    }
}

@media screen and (min-width: $breakpoint-xl) {
    .arterial-road-status  {
        &__code {
            font-size: $font-size-md-dynamic;
        }
        &__name {
            font-size: calc(2px + #{$font-size-lg-dynamic});
        }
        &__travel-time {
            font-size: $font-size-xxl-dynamic;
        }
        &:before {
          left: -0.25vw;
        }
    }
}

@media screen and (max-width: $breakpoint-xl) {
    .arterial-road-status  {
        &__code {
            font-size: $font-size-sm;
        }
        &__name {
            font-size: $font-size-md;
        }
        &__travel-time {
            font-size: $font-size-xl;
        }   
    }
}

  