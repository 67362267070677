@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;200;300;400;500;600;700;800;900&display=swap');

// Fonts
$font-family-base: 'Roboto', Arial, sans-serif;
$font-size-base: 1rem; // 16px

$font-size-xxxl: 3.125rem; // 50px
$font-size-xxl: 2.5rem; // 40px
$font-size-xl: 2rem; // 32px
$font-size-lg: 1.5rem; // 24px
$font-size-md: 1.25rem !default; // 20px
$font-size-sm: 0.9rem; // 14.4px
$font-size-xs: 0.8rem; // 12.8px
$font-size-index: 0.7rem; // 11.2px

$font-size-nav: 1rem; // 14.4px
$font-size-weather-card-toggle: 0.625rem; // 10px
$font-size-weather-table-row-text: 0.875rem; // 14px
$font-size-weather-table-row-subtext: 0.8125rem; // 14px

// font sizes that scale dynamically based on viewport width only
$font-size-xxl-dynamic: calc(2.8vw);
$font-size-xl-dynamic: calc(2.5vw);
$font-size-lg-dynamic: calc(1.5vw);
$font-size-md-dynamic: calc(1.2vw);
$font-size-sm-dynamic: calc(1vw);
$font-size-xs-dynamic: calc(0.6vw);

$navbar-initials-font-size: 0.75rem; // 12px

$line-height-computed: 1.5rem;
$line-height-weather-card-sub-unit: 1.23rem;


$font-weight-thin: 300;
$font-weight-normal: 400;
$font-weight-semi-bold: 600;
$font-weight-bold: 700;

$glyphicons-halflings-include-bonus: false !default;
$glyphicons-halflings-class-prefix: glyphicon !default;
$glyphicons-halflings-font-base-size: 14px !default;

$glyphicons-halflings-font-path: "../fonts/glyphicons" !default;

// Colours
$gray-100: #f9f6f2;
$gray-200: #efedea;
$gray-300: #d8d8d8;
$gray-400: #ccc;
$gray-500: #666;
$gray-600: #6c757d;
$gray-700: #2e3138;
$gray-800: #333;
$gray-900: #2d3136;

$gray-light: $gray-100;
$text-muted: $gray-300 !default;

$brand-primary:         #3754a5 !default; // #337ab7
$brand-secondary:       #b9d9eb !default;
$brand-success:         #1ab394 !default;
$brand-info:            #5d80f9 !default;
$brand-warning:         #d3bc8d !default;
$brand-danger:          #eb3349 !default;
$edp-brand-purple:      #6900ff !default;

$red-alert-color: #fe4a49;
$amber-alert-color: #fed766;
$green-alert-color: #24ccb8;
$info-alert-color: #3C6DF0;

$arterial-road-row-color: transparent;

$light-background-color: #eee;
$dark-background-color: #222529;

$dark-font-color: #353735;
$light-font-color: #fff;

$body-bg: $dark-background-color !default;

$text-color: $light-font-color;
$link-color: #337ab7;

$notification-small-color: rgba(#000, 0.6);
$notification-unread-bg: #fff8eb;
$notification-hover-bg: #f1f1f1;

$golden: #d6b986;

$primary:       $text-color !default;

$state-success-text:             $brand-success !default;
$state-success-bg:               lighten($brand-success, 70%) !default;

$state-info-text: $brand-info !default;
$state-info-bg: lighten($brand-info, 70%) !default;

$state-warning-text: $brand-warning !default;
$state-warning-bg: lighten($brand-warning, 70%) !default;

$state-danger-text: $brand-danger !default;
$state-danger-bg: lighten($brand-danger, 70%) !default;

$alert-success-bg: #e6fff2 !default;
$alert-success-text: $state-success-text !default;
$alert-success-border: rgba($alert-success-text, 0.15) !default;

$alert-info-bg: #d2f1fb !default;
$alert-info-text: $link-color !default;
$alert-info-border: rgba($alert-info-text, 0.09) !default;

$alert-warning-bg: #fff8e6 !default;
$alert-warning-text: darken($state-warning-text, 5%) !default;
$alert-warning-border: rgba($state-warning-text, 0.2) !default;

$alert-danger-bg: #fbdcd7 !default;
$alert-danger-text: $state-danger-text !default;
$alert-danger-border: rgba($alert-danger-text, 0.09) !default;

// Gradients
$logo-gradient: linear-gradient(to right, $brand-danger, #f45c43);

$max-content-width: 1000px;
$max-navbar-width: 100vw;

// $grid-float-breakpoint: 0;

// /*
//  * Media queries breakpoints
//  * ======================================================================== */

// $screen-xs-min: 480px;  /* Extra small screen / phone */
// $screen-sm-min: 768px;  /* Small screen / tablet */
// $screen-md-min: 992px;  /* Medium screen / desktop */
// $screen-lg-min: 1200px; /* Large screen / wide desktop */

// width at which footer should become taller
$breakpoint-footer-width: 360px;

// as used in reactstrap for 'expand' props
$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-height-mobile: 750px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;

$content-padding-horizontal: 30px;
$content-padding-vertical: 40px;

$navbar-bg-color: #4b5159;
$navbar-height:  41px !default;
$footer-height: $navbar-height;
$navbar-margin-bottom: -$navbar-height !default;
$navbar-nav-link-padding-x: 0.6rem !default; // Spacing between nav links; .navbar-nav .nav-link
$navbar-horizontal-padding: 5em;

$board-horizontal-padding: $navbar-horizontal-padding;
$board-vertical-padding: 20px;

// Area below the navbar
$detail-with-secondary-height: calc(100vh - (#{$navbar-height})); // navbar
$landing-page-height: calc(100vh - (#{$navbar-height} + #{$navbar-height})); // navbar + footer
$full-screen-card-with-title-height: calc(#{$detail-with-secondary-height} - #{$font-size-xl} - #{$board-vertical-padding * 2});

$detail-height: calc(100vh - #{$navbar-height}); // navbar

$sidebar-width-open: 200px;
$sidebar-transition-time: 0.3s !default;

$sidebar-bg-color: #fff;
$sidebar-color: #4d4d4d;

$breadcrumb-separator: ">";
$breadcrumb-padding-horizontal: 0;
$breadcrumb-bg: transparent;

$breadcrumb-divider: ">";
$breadcrumb-padding-y: 0;
$breadcrumb-padding-x: 0;
$breadcrumb-bg: transparent;

$widget-bg: #393e44;
$widget-title-color: #444;
$widget-padding-y: 15px;
$widget-padding-x: 20px;

$btn-inverse-color: #fff;
$btn-inverse-bg: $gray-700;
$btn-inverse-border: #414749;

$input-height-small: 2rem;

// Buttons
$border-width: 1px;
$border-radius: 0.25rem;

// Card
$card-box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.4);
$card-bg: $gray-900;
$card-bg-lighter: #393E44;
$parent-card-bg-color: #3a3e43;
$card-nav-height: 62px;
$card-nav-font-size: 20px;
$card-nav-active-color: rgba(127, 131, 135, 0.5);
$card-border-radius: 3px;
$card-button-border-radius: $card-border-radius;
$card-padding: 25px;
$inner-card-padding: 15px;
$card-title-font-size: $font-size-md;
$toggle-width: 136px;
$toggle-item-width: calc(#{$navbar-height} - 2px) ;
$toggle-background: #42464A;
// card height defaults
$card-powerbi-default-height: 28vw;
$card-powerbi-default-height-taller: 32vw;
$card-minimum-height-default: 290px;

// Dropdowns
// Dropdown menu container and contents.
$dropdown-border-radius: 0;
$dropdown-link-active-bg: $gray-900;
$content-dropdown-subheading-font-size: 13px;
$content-dropdown-padding: 24px;
$content-dropdown-width: 350px;
$content-dropdown-inputs-border: 0;
$content-dropdown-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
$content-dropdown-bg: #fff;
$dropdown-min-width: 8rem;
// Contact Us Dropdown
$contact-us-dropdown-height: 16em;
$dropdown-button-size: 15px;

$emphasized-link-hover-darken-percentage: 0; // Don't darken emphasized links (see https://github.com/twbs/bootstrap/pull/27880/commits/e820fbc0f1e17edc069d7719fe2fcbdff62fa607)

// variables based off Shawn's new design (Int Arrivals Live board)
$peach-1: #ff9898;
$orange-1: #ffa76d;
$light-grey-1: #C7C8CB;
$light-grey-2: rgba(255,255,255,0.9);
$dark-grey-1:  rgba(216, 216, 216, 0.05);
$card-title-color: $light-grey-1;
$card-subtitle-color: $light-font-color;
$card-content-top-padding: 17px;
$card-content-bottom-padding-large: 22px;
$card-content-bottom-padding-medium: 15px;
$card-content-bottom-padding-small: 4px;
$card-content-horizontal-padding: 19px;
$padding-vertical-2: 46px;
$padding-horizontal-2: 34px;

// Notification filter colour
$filter-heading-color: #231f20;
$filter-text-color: #595656;

// Charts
// chart heights
$queue-times-height: 190px;
$minimum-chart-height: 320px;

// chart colors
$chart-blue: #3c6df0;
$chart-light-blue: #b9d9eb;
$chart-purple: #b769e0;
$chart-pink: #ca85cf;
$chart-yellow: #dcecc9;
$chart-grey: #979797;
$chart-label-color: rgba(255,255,255,0.6);

// series colors
$primary-series-color: $chart-blue;
$secondary-series-color: $chart-purple;
$tertiary-series-color: $chart-yellow;
$base-series-color: $chart-grey;
// other series colors
$primary-series-color-2: $chart-light-blue;
$secondary-series-color-2: $chart-pink;
// versions to be used when printing
$primary-series-color-printer-version: $chart-blue;
$secondary-series-color-printer-version: $chart-purple;
$tertiary-series-color-printer-version: $chart-purple;
$base-series-color-printer-version: $chart-grey;

// printing charts
$chart-printing-width: 940px;
$chart-printing-height: 530px;

:export {
    // EDP base colors
    edpPurple: $edp-brand-purple;

    // colors for charts
    primarySeriesColor: $primary-series-color;
    primarySeriesColorPrinting: $primary-series-color-printer-version;
    secondarySeriesColor: $secondary-series-color;
    secondarySeriesColorPrinting: $secondary-series-color-printer-version;
    tertiarySeriesColor: $tertiary-series-color;
    tertiarySeriesColorPrinting: $tertiary-series-color-printer-version;
    baseSeriesColor: $base-series-color;
    baseSeriesColorPrinting: $base-series-color-printer-version;
    chartLabelColor: $chart-label-color;

    amberAlertColor: $amber-alert-color;
    greenAlertColor: $green-alert-color;
    redAlertColor: $red-alert-color;

    // other chart-specific colors
    flightBarChartsPrimaryColor: $primary-series-color-2;
    flightBarChartsSecondaryColor: $secondary-series-color-2;
}
