$internal-roads-arrow-indicator-size: 5px;
$internal-roads-arrow-indicator-size-dynamic: calc(1px + 0.3vw);

.edp-card {
  &__body {
    &--travel-time-to-airport {
      @media screen and (min-width: $breakpoint-xl) {
        padding-right: 5%;
      }
    }
  }
}

.internal-roads {
  color: $light-font-color;

  &__col {
    padding: 0;
    display: flex;
    align-items: center;
    
    &--left {
      text-align: right;
    }
    &--right {
      text-align: left;
    }
  }

  &__duration {
    margin: 0 auto;
  }

  &__duration {
    line-height: 1;
  }

  &__from,
  &__to {
    line-height: 1.2em;
  }
  
  &__arrow-indicator,
  &__from,
  &__to {
    vertical-align: middle;
  }

  &__from {
    position: absolute;
    right: 0;
  }

  &__row {
    display: flex;
    justify-content: space-around;
    width: 100%;
    margin-bottom: 12px;
    padding-right: 10px;
    &:last-child {
      margin-bottom: 0;
    }
  }  
  
  &__arrow-indicator {
    border: solid black;
    border-width: 0 $internal-roads-arrow-indicator-size $internal-roads-arrow-indicator-size 0;
    display: inline-block;
    padding: $internal-roads-arrow-indicator-size;
    &-container {
      text-align: center;
      margin: 0 auto; // horizontally centered
    }

    // direction the arrow is pointing to
    &.right {
      margin-left: calc(-1 * #{$internal-roads-arrow-indicator-size});
      transform: rotate(-45deg);
      -webkit-transform: rotate(-45deg);
    }

    // traffic status of the indicator
    &.red-status {
      border-color: $red-alert-color;
    }
    &.amber-status {
      border-color: $amber-alert-color;
    }
    &.green-status {
      border-color: $green-alert-color;
    }
  }
}

$internal-roads-breakpoint: $breakpoint-xl + 150px;

@media screen and (min-width: $internal-roads-breakpoint) {
  .internal-roads {
    &__duration {
      font-size: $font-size-xl-dynamic;
    }
    &__from, 
    &__to {
      font-size: $font-size-sm-dynamic;
    }
    &__row {
      margin-bottom: calc(8px + 0.1vh);
    }
    &__row:last-of-type {
      margin-bottom: 0px;
    }
    &__arrow-indicator {
      border-width: 0 $internal-roads-arrow-indicator-size-dynamic $internal-roads-arrow-indicator-size-dynamic 0;
      padding: $internal-roads-arrow-indicator-size-dynamic;
    }
  }
}

@media screen and (max-width: $internal-roads-breakpoint) {
  .internal-roads {
    &__duration {
      font-size: $font-size-lg;
    }
    &__from, 
    &__to {
        font-size: $font-size-xs;
    }
  }
}
