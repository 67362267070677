.flights-card {
    overflow: hidden;
    &__input {
        overflow: visible;
        border: none;
        display: block;
        width: 100%;
        height: 100%;
        background: transparent;
        -webkit-appearance: none;
        color: $light-font-color;
        display: flex;
        align-items: center;
    }
    &__icon {
        width: 30px;
        filter: invert(1);
        margin-right: 15px;
        &--arr {
            transform: rotate(30deg);
        }
    }
    &__table {
        width: 100%;
        height: 100%;
        &.d-inline-table {
            display: inline-table !important;
        }
        &--center {
            justify-content: center;
            text-align: center;
            .flights-card__input {
                text-align: center;
                justify-content: center;
            }
        }
        &-header {
            border-bottom: 1px solid #7F8287;
            &-row {
                height: 2.1em;
            }
            &-cell {
                padding: 0.7em 0.6em;
                font-weight: $font-weight-normal;
                color: $card-title-color;
                font-size: calc(1vw);
            }
        }
        &-footer {
            &-cell {
                padding: 0.7em 0.6em;
                font-weight: $font-weight-normal;
                color: $card-title-color;
                font-size: calc(1vw);
            }
        }
        &-body {
            &-row {
                height: 2.1em;
                &.odd {
                    background-color: #4A4F54;
                }
                &.highlighted {
                    background-color: #fff;
                    font-weight: 900 !important;
                    td span{
                        color: #000;
                    }
                }
            }
            &-cell {
                padding: 0.5em 0.6em;
                width: 0;
                &--red {
                    background-color: $red-alert-color;
                }
                &--amber {
                    background-color: $amber-alert-color;
                    .flights-card__input {
                        color: #000;
                    }
                }
                &--green {
                    background-color: $green-alert-color;
                    .flights-card__input {
                        color: #000;
                    }
                }
                &--orange {
                    background-color: #ed7d31;
                    .flights-card__input {
                        color: #000;
                    }
                }
                &--brown {
                    background-color: #dc5d44;
                }
                &--status {
                    min-width: 6em;
                }
            }
        }
        &-header-cell,
        &-body-cell {
            @media screen and (min-width: $breakpoint-xl) {
                /* font-size: 0.75em; */
                font-size: calc(.65vw)
            }
            @media screen and (max-width: $breakpoint-xl) {
                font-size: 0.8em;
            }
            border-right: 1px solid #484F60;
            &:last-child {
                border-right: none;
            }
            &.min-size {
                min-width: 35px;
                height: 130px;
            }
        }

        @media screen and (max-width: $breakpoint-sm) {            
            overflow-x: scroll;
            display: block;
        }
    } 
}