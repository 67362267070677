// hide printing logo and printer-friendly graphs by default
#edp-print-logo {
    display:none;
}

.edp-card {
    &__graph {
        &--printer-version {
            display: none;
        }
    }
}
.edp-print {
    &__page-break {
        height: 0;
        margin: 0;
        page-break-before: always;
    }
}

.print-item {
    display: none;
}

@media print {
    html, body {
        // set height to auto (avoids a line break causing a blank page)
        height: auto;
        width: 210mm;
        /* // full page height;
        height: 297mm; */
        height: 282mm;
    }

    .print-item {
        display: block;
    }

    @page {
        size: landscape;
        // set the page margin to 0.5 inches for a better experience 
        // browser default printing margins vary 0.25-0.5 inches, except IE which has 0.75 inches
        // printers also have their own default margins - these vary from 0.25-0.75 inches too.
        margin-top: 0.75in;
        margin-bottom: 0.75in;
        margin-left: 0.75in;
        margin-right: 0.75in;    
    }

    // set axis labels, legend, plot band labels and card title to dark font color
    .highcharts-axis-labels > text,
    .highcharts-legend-item > text,
    text.highcharts-plot-band-label {
        fill: $dark-font-color !important;
        font-weight: $font-weight-bold;
    }

    // remove board padding
    .edp-board {
        padding: 0;
        page-break-after: auto; 

        &__margin-align-nav {
            padding: 0;
        }
        &__margin-align-nav {
            padding: 0;
            page-break-after: auto;
        }
        &__col {
            padding-bottom: 0;
        }
    }

    .edp-flights-breakdown {
        margin: 0 !important;

        &__table {
            th, td {
                color: $dark-font-color !important;
            }
            tr:not(.odd-row) td {
                box-shadow: inset 0 0 0 1000px $gray-200 !important;
            }
        }
        &__col {
            width: 33%;
            flex: none;
        }
        &__table {
            & td.flight-no {
                font-weight: $font-weight-bold;
                font-size: $font-size-md;
            }
        }
    }

    .edp-card {

        // prevent cards from being oversized when printing
        height: inherit;

        // hide all cards that are not printable `__graph--printer-version`
        // display: none;
        // hide normal graphs and show printer-friendly versions
        &__graph {
            &--printable {
                display: none;
            }
            &--printer-version {
                display: block !important;
            }
        }
        &__body {
            height: unset;
        }
        &__content {
            // reduce card padding when printing
            padding: 5px;
            max-width: $chart-printing-width;
            background: none !important;
        }
        // adjust card titles
        &__title,
        &__subtitle {
            font-size: 18px;
            color: $dark-font-color;
        }
        &__toggle,
        &__footer {
            display: none;
        }
        // reduce top padding on card headers
        &__header {
            padding-top: 5px;
        }
        &__toggle-dropdown-toggle {
            border-color: grey !important;
        }

        // force dropdown toggle to be horizontal rather than vertical
        &__toggles-container {
            flex-direction: row;
        }
        &__toggle-li {
            &:not(.selected) {
                display: none !important;
            }
            &.selected {
                border: 1px solid $dark-font-color !important;
                color: $dark-font-color !important;
            }
        }

        table {
            tbody, thead, tr, td, th, span, input {
                background-color: inherit !important;
                color: $dark-font-color !important;
                border-color: inherit !important;
                height: inherit !important;
                font-size: 12px !important;
            }
        }
    }

    // hide the `caret` downward arrow on the dropdown
    .dropdown-toggle::after {
        display: none;
    }
    
    // hide tooltips
    .edp-tooltip__anchor {
        display: none;
    }

    // show the print logo (hidden by default)
    #edp-print-logo {
        page-break-before: always;
        display: block;
    }
    .hide-when-printing {
        display: none !important;
    }
    .show-when-printing {
        display: block !important;
    }

    img {
        width: auto !important;
    }
}