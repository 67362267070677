$navbar-background-color: #4b5159;

.nav__link {
  list-style-type: none;
  font-weight: $font-weight-thin;
  font-size: 1rem;
  white-space: nowrap;
  padding-left: 0.45rem;
  padding-right: 0.45rem;
  color: $light-font-color;
  margin-top: auto;
  margin-bottom: auto;
  text-decoration: none;

  &:hover.clickable {
    background: #434950;
  }
  &:active.clickable {
    background: #3C4147;
    outline: none;
  }
  &:focus,
  &:visited,
  &:active,
  &:hover {
    color: $light-font-color;
  }

  &--primary &--active {
      color: #000;
      background-color: $brand-warning !important;
      border-color: $brand-warning;
      font-weight: $font-weight-bold;
      &:hover, &:focus, &:visited, &:active {
        color: #000;
      }
  }
  &--secondary &--active > span {
    border-bottom: 4px solid $brand-warning;
    color: $light-font-color;
    padding-bottom: 7px;
    font-weight: $font-weight-bold;
  }
}

.nav__click {
  cursor: pointer;
}

.nav_icon-link {
  margin-top: auto;
  margin-bottom: auto;
  padding: 0.5rem 1rem;
}

.nav__icon {
  height: 25px;
  width: 25px;
}

.nav__dropdown-menu {
  border-radius: 3px;
  background-color: $navbar-background-color;
  border-color: white;
  color: white;
  padding: 0;
  &:hover, &:focus, &:visited, &:active {
    background-color: $navbar-background-color;
  }
}

.nav__dropdown-menu--nav {
  margin-top: 11px;
}

.nav__dropdown-menu--form {
  padding: 0;
}

.nav__link--dropdown-item {
  padding-top: 1em;
  padding-bottom: 1em;
  &:focus, &:visited, &:active {
    background-color: $navbar-background-color;
  }
  &:hover {
    background-color: #fff;
    color: #333;
  }
}

.nav__signout:active, 
.nav__signout:active, 
.nav__signout:hover, 
.nav__signout:focus {
  background-color: unset;
  color: #000;
}

.navbar .navbar__primary {
  height: $card-nav-height;
}

.navbar-nav .nav-link {
  padding-right: 0.6rem;
  padding-left: 0.6rem;
}

.navbar {
  padding-left: 15px !important;
  padding-right: 15px !important;
  &__avatar {
    background-color: $brand-warning;
    border-radius: 50%;
    cursor: pointer;
    height: 25px;
    text-align: center;
    width: 25px;
  }

  &__avatar-initials {
    color: $light-font-color;
    font-size: $navbar-initials-font-size;
    letter-spacing: normal;
    line-height: 2.25;
    margin: 0 auto;
  }

  .sign-out-dropdown {
    left: -80px !important;
    top: $navbar-height !important;
  }
}

.nav-link-divider {
  background-color: #fff;
  height: 16px;
  width: 2px;
  margin: 0 5px;
}

.noselect {
  -khtml-user-select: none; 
  -moz-user-select: none; 
  -ms-user-select: none; 
  -webkit-touch-callout: none; 
  -webkit-user-select: none; 
  user-select: none; 
}


.dropdown-item{
  &:focus {
    outline: 0;
    color: inherit;
    background-color: inherit;
  }
}

.edp-navbar {
  &__dropdown-menu {
    padding: 0.4em 0;
    margin: 0;
    box-shadow: $content-dropdown-box-shadow;
  }
  &__dropdown-item {
    color: inherit;
    font-size: $font-size-sm;
    min-height: 2.5em;
    padding: 0.6rem 1.5rem;
    &:active {
      color: inherit;
    }
    &:hover {
      color: inherit;
    }
    
  }
  &__burger-icon {
    fill: white;
    background: transparent;
    border: none;
    border-radius: 3px; // for the hover, active and focus
    margin-right: 12px;
    padding: 5px;

    &:hover {
      background: lighten($navbar-background-color, 3%);
      cursor: pointer;
    }
    &:active,
    &:focus {
      outline: none;
    }
    &:active {
      background: darken($navbar-background-color, 1%);
      cursor: pointer;
    }
  }
  &__bread-crumb {
    font-size: $font-size-nav;
    @media screen and (max-width: $breakpoint-md) {
      display: none !important;
    }
  }
}

.edp-support-form {
  background-color: $content-dropdown-bg;
  padding: $content-dropdown-padding;
  width: 20em;
  &__heading {
    display: inline-block;
    font-size: $font-size-md;
  }

  &__subheading {
    font-size: $font-size-base;
    margin-top: 20px;
  }

  &__icon-button {
    cursor: pointer;
    float: right;
    height: $dropdown-button-size;
    margin-right: -5px;
    margin-top: -5px;
    width: $dropdown-button-size;
  }

  &__subject:hover,
  &__button:hover {
    cursor: pointer;
  }

  &__subject,
  &__body,
  &__textarea,
  &__user-email,
  &__user-name {
    border: 1px solid #000;
    border-radius: 0;
  }

  &__content-centered {
    display: table;
    text-align: center;
  }

  &__content,
  &__content-centered {
    height: 75%;
  }

  &__prompt {
    display: block;
    margin: 0 auto;
    width: 70%;
  }

  &__body {
    height: 65%;
  }

  &__textarea {
    height: $contact-us-dropdown-height !important;
    resize: none;
  }
  // override default is-valid and is-invalid styling
  &__textarea.is-invalid.form-control {
    padding: .375rem .75rem !important;
  }

  &__input-text,
  &__input-select,
  &__textarea {
    // -webkit-appearance: none;
    border: $content-dropdown-inputs-border !important;
  }

  &__input-select,
  &__textarea,
  &__textarea::placeholder,
  &__input-text,
  &__input-text::placeholder {
    font-size: $content-dropdown-subheading-font-size !important;
  }

  &__textarea::placeholder,
  &__prompt {
    color: #9b9b9b !important;
  }

  &__textarea::placeholder {
    width: 75%;
  }

  &__button {
    display: block; 
    margin: 0 auto;
    width: 9.5em;
  }

  &__loader {
    height: 100%;
  }
  // set minimum heights so that the feedback form does not change height depending on its contents
  &__content-landing-min-height {
    min-height: 420px;
  }

  &__content-min-height {
    min-height: 317px;
  }
}
@media (min-width: $breakpoint-lg) {
  .edp-support-form {
    width: $content-dropdown-width;
  }
}

.set-min-height-landing {
  min-height: 572px;
}

.set-min-height {
  min-height: 469px;
}

// position red x and green tick correctly (requires override)
.form-control.is-invalid,
.form-control.is-valid {
  background-position: 97% calc(0.375em + .1875rem) !important;
}

// add red / green focus when the input is invalid / valid
.form-control.is-invalid {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.form-control.is-valid {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

// analog clock that appears in the navigation bar
.edp-nav-clock {
  @media screen and (max-width: $breakpoint-sm) {
    display: none !important;
  } 

  &__analog {
    vertical-align: middle;
    display: inline-block;
    margin-right: 0.5em;;
    border-radius: 50%;
    background-color: #fff;
    position: relative;
  
    & .center {
      background-color: #000;
      width: 1px;
      height: 1px;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
      z-index: 6;
    }
    & .hand {
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -100%);
              transform: translate(-50%, -100%);
      -webkit-transform-origin: center bottom;
              transform-origin: center bottom;
      border-radius: 2.25px;
    }
    & .h-hand {
      height: 7.25px;
      width: 1.7px;
      background-color: #000;
      z-index: 3;
    }
    & .m-hand {
      height: 10px;
      width: 2px;
      background-color: #000;
      z-index: 4;
    }
  }
}
	