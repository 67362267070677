$alternate-card-bg: #434851;

.edp-flights-breakdown {
    margin-top: 1.5em;

    &__card {
        height: 100%;
        padding: 7.5px 0;
        &.taller {
            min-height: 25em;
        }
        &--content {
            box-shadow: $card-box-shadow;
            background: $alternate-card-bg;
        }
    }

    &__close-btn {
        margin: 1em 0.5em 0 0.5em;
        float: right;

        &:hover {
            cursor: pointer;
        }
    }
    &__close-icon {
        transform: rotate(180deg);
    }
    &__close-icon,
    &__close-btn {
        width: 1.5em;
        height: 1.5em;
    }

	&__table {
		overflow: hidden;
		width: 100%;
        margin: 0 auto;

        border-radius: $card-border-radius;
		-moz-border-radius: $card-border-radius; /* Firefox 1+ */
		-webkit-border-radius: $card-border-radius; /* Safari 3-4 */
    
        th, td {
            text-align: center;
            color: $chart-label-color;
        }
        
        th {
            padding: 12px 15px;
            font-weight: normal;
            font-size: 1rem;
            background: $alternate-card-bg;
            border-bottom: 1px solid;
    
            & > span.emphasized {
                color: $light-font-color;
                font-weight: $font-weight-bold;
            }
        }
        
        td {
            padding: 22px 0.25em;
        }
        
        tr:not(.odd-row) td {
            background: #4A4F54;
        }
        
        td.first,
        th.first {
            text-align: left;
        }
        
        td.last {
            border-right: none;
        }
        
        /* additional styling so webkit will recognize rounded corners on background elements. */
        tr:first-child th.first {
            border-top-left-radius: $card-border-radius;
            -moz-border-radius-topleft: $card-border-radius;
            -webkit-border-top-left-radius: $card-border-radius; /* Safari 3-4 */
        }
        
        tr:first-child th.last {
            border-top-right-radius: $card-border-radius;
            -moz-border-radius-topright: $card-border-radius;
            -webkit-border-top-right-radius: $card-border-radius; /* Safari 3-4 */
        }
        
        tr:last-child td.first {
            border-bottom-left-radius: $card-border-radius;
            -moz-border-radius-bottomleft: $card-border-radius;
            -webkit-border-bottom-left-radius: $card-border-radius; /* Safari 3-4 */
        }
        
        tr:last-child td.last {
            border-bottom-right-radius: $card-border-radius;
            -moz-border-radius-bottomright: $card-border-radius;
            -webkit-border-bottom-right-radius: $card-border-radius; /* Safari 3-4 */
        }
    }
}

