.input-range {
  $dot-size: 13px;
  $margin-bottom: 1.8rem;
  $track-color: #d9dcdd;
  $track-color-active: #7e8185;

  height: 1rem;
  position: relative;
  width: auto;
  margin-left: 16px;
  margin-right: 16px;
  margin-bottom: $margin-bottom;

  &-container {
    position: relative;
  }

  &__slider {
    background: $widget-bg;
    border-color: $widget-bg;
    border-radius: 100%;
    appearance: none;
    cursor: pointer;
    display: block;
    height: $dot-size;
    width: $dot-size;
    margin-left: -0.5rem;
    margin-top: -$dot-size / 2;
    outline: none;
    position: absolute;
    top: 50%;
    transition: transform 0.1s ease-out, box-shadow 0.1s ease-out;
  }

  &__slider:active {
    transform: scale(1.3);
  }

  &__slider:focus {
    box-shadow: 0 0 0 5px rgba(63, 81, 181, 0.2);
  }

  &--disabled &__slider {
    background: #cccccc;
    border: 1px solid #cccccc;
    box-shadow: none;
    transform: none;
  }

  &__slider-container {
    transition: left 0.3s ease-out;
  }

  &__label {
    color: $filter-text-color;
    font-family: "Helvetica Neue", san-serif;
    font-size: 0.8rem;
    transform: translateZ(0);
    white-space: nowrap;
  }

  &__label--min,
  &__label--max {
    display: none;
    top: -1.4rem;
    position: absolute;
  }

  &__label--min {
    left: 0;
  }

  &__label--max {
    right: 0;
  }

  &__label--value {
    position: absolute;
    top: auto;
    bottom: -$margin-bottom;
  }

  &__label-container {
    left: -50%;
    position: relative;
  }

  &__label--max &__label-container {
    left: 50%;
  }

  &__track {
    background: $track-color;
    border-radius: 0.3rem;
    cursor: pointer;
    display: block;
    height: 1px;
    position: relative;
    transition: left 0.3s ease-out, width 0.3s ease-out;
  }

  &--disabled &__track {
    background: #eeeeee;
  }

  &__track--background {
    left: 0;
    margin-top: -0.15rem;
    position: absolute;
    right: 0;
    top: 50%;
  }

  &__track--active {
    background: $track-color-active;
  }

  &__label--external {
    width: 100%;
    &--min,
    &--max {
      position: absolute;
      bottom: -1.2rem;
      top: auto;
      display: inline-block;
    }
    &--min {
      left: 0;
    }
    &--max {
      right: 0;
    }
  }
}