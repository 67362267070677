$normal-card-layout-width: 23rem;
$reduced-card-layout-width: 18rem;

.edp-card-layout {
    &__col {
        padding: 0 7.5px;
    }
}

@media screen and (min-width: $internal-roads-breakpoint) {
    .edp-card-layout {
        &__content {
            width: $normal-card-layout-width;
            &--smaller {
                width: 17.7rem;
            }
            &--duo {
                width: $normal-card-layout-width * 2;
            }
            &--trio {
                width: $normal-card-layout-width * 3;
            }
        }
    }
}
@media screen and (max-width: $internal-roads-breakpoint) {
    .edp-card-layout {
        &__content {
            width: $reduced-card-layout-width;
            &--smaller {
                width: $reduced-card-layout-width;
            }
            &--duo {
                width: $reduced-card-layout-width * 2;
            }
            &--trio {
                width: $reduced-card-layout-width * 3;
            }
        }
    }
}