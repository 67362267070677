.edp__filter {
    position: relative;
    &-title {
        color: $filter-heading-color;
        font-weight: $font-weight-bold;
        font-size: $font-size-base;
        margin-bottom: 10px;
    }
    &-icon {
        width: 20px;
        height: 20px;
        margin-right: 15px;
        margin-left: 15px;
        fill: $light-grey-1;
        &:hover {
            cursor: pointer;
        }
    }
    &-btn {
        font-size: $font-size-sm;
        color: $light-grey-1;
    }
    &-dropdown {
        position: absolute;
        right: 0;
        margin-top: 8px;
        width: 190px;
        padding: 24px;
        background: #fff;
        color: $filter-text-color;
        box-shadow: 0 0 9px 2px rgba(68, 68, 68, 0.5);
        z-index: 1;
        font-size: $font-size-sm;
        &.graph-filter {
            width: 200px;
            right: 29px;
        }
        &.custom-filter {
            width: 300px;
            margin-top: 50px;
            right: 15px;
            padding: 20px 20px 10px 20px;
        }
    }

    &-checkbox{
        &--label {
            font-size: $font-size-xs;
            margin-top: 2px;
            color: $filter-heading-color;
            cursor: pointer;
        }
    }
    // &-list {
    //     display: flex;
    //     justify-content: flex-start;
    //     align-items: center;
    // }
    &-group {
        height: 305px;
        overflow-y: scroll;
        margin-bottom: 15px;
        input:focus {
            border: 1px solid #000;
        }
        .form-check {
            padding-top: 10px;
            padding-bottom: 10px;
            padding-right: 10px;
            padding-left: 30px;
            border-bottom: 1px solid #e8e8e8;
        }
    }
    
    &-input {
        display: flex;
        flex-direction: column;
        margin-bottom: 16px;
        .label {
            color: $filter-heading-color;
            font-weight: $font-weight-bold;
            margin-bottom: 0;
        }
        input {
            width: 100%;
        }
    }
    &-search {
        font-size: $font-size-sm;
        &:focus {
            border: 1px solid #000;
        }
    }
    &-apply {
        padding: 4px 0;
        color: #fff;
        font-weight: bold;
        text-transform: uppercase;
    }
    &-reset {
        padding: 4px 0;
        color: #000;
        font-weight: bold;
        text-transform: uppercase;
        background-color: transparent;
    }
    &s-applied {
        padding: 12px;
        border-top: 1px solid $light-grey-1;
        font-size: $font-size-sm;
        display: flex;
        justify-content: center;
        .badge {
            display: flex;
            align-items: center;
            font-size: inherit;
            font-weight: $font-weight-bold;
            background-color: $golden;
            padding: 4px 8px;
        }
        svg {
            width: 12px;
            height: 12px;
            margin-left: 16px;
        }
    }
}

@media screen and (max-width: $breakpoint-md) {
    .edp__filter {
        &-dropdown {
            &.graph-filter {
                margin-top: 5px;
            }
        }
    }
}

@media screen and (min-width: $breakpoint-md) {
    .edp__filter {
        &-dropdown {
            &.graph-filter {
                margin-top: 37px;
            }
        }
    }
}