.edp-button {
    &--dark {
        font-size: 14px;
        padding: 4px 16px;
        border-radius: 5px;
        border: 1px solid $light-grey-1;
        color: $light-grey-1;
        background: transparent;
        &.inverted {
          background: $orange-1;
          font-weight: $font-weight-bold;
          border: 1px solid $orange-1;
          color: $gray-900;
        }
        &:focus {
          outline: none;
        }
    }
    &--unstyled {
      background: none;
      color: inherit;
      border: none;
      padding: 0;
      outline: inherit;
      font: inherit;
      &:focus {
        outline: none;
      }
    }
}

.button {
  border: 0;
  border-radius: 0;
  display: inline-block;
  padding: 0.9375rem 0;
  text-align: center;
  text-decoration: none;
  -webkit-transition: all, .3s;
  transition: all, .3s;
  width: 100%;
  -webkit-appearance: none
}

.button--primary {
  background: #d6b986;
  color: #353735
}

.button--primary:hover,
.button--primary.hover-state {
  background: #353735;
  color: #d6b986
}

.nav-item--dark{
  color: white;
}