$nav-side-bar-primary-color: $navbar-bg-color;
$navigation-border-radius: 3px;
$nav-side-bar-width: calc(8em + #{$navigation-border-radius});

.edp-nav-side-bar {
    &__primary {
        &-navigation {
            padding: 0 15px;
        }
        &-container {
            z-index: 99;
            position: fixed;
            display: inline-block;
            margin: 0;
            background: $nav-side-bar-primary-color;
            min-height: -webkit-fill-available;
            min-height: $detail-with-secondary-height;
            transition: 0.25s all ease;

            overflow: hidden;
            text-align: center;

            &.expanded {
                width: $nav-side-bar-width;
            }
            &:not(.expanded) {
                width: 0;
            }
        }
        &-item {
            min-height: 3em;
            padding: 10px 20px 10px 10px;
            color: $light-font-color;
            border-radius: $navigation-border-radius;
            font-size: $font-size-nav;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: left;
            white-space: nowrap;
            &:hover {
                cursor: pointer;
            }
            &-name {
                display: inline-block;
                margin-top: 5px;
            }
            & > .title.active {
                font-weight: $font-weight-bold;
                border-left: 4px solid $edp-brand-purple;
            }

            &.active {
                color: $nav-side-bar-primary-color;
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
                border-top-left-radius: 5px;
                border-bottom-left-radius: 5px;
                background: #ccc;
            }
        }
        &-arrow-icon {
            height: 15px;
            width: 15px;
            transform: rotate(90deg);
            float: right;
            stroke: white;
        }
    }

    &__secondary {
        &-container {
            z-index: 99;
            position: fixed;
            left: $nav-side-bar-width;
            display: inline-block;
            background: #ccc;
            min-height: -webkit-fill-available;
            min-height: $detail-with-secondary-height;
            border-radius: $navigation-border-radius;
            margin-left: -1 * $navigation-border-radius;
        }
        &-item {
            min-height: 2em;
            padding: 5px 15px;
            margin: 12px;
            font-size: $font-size-nav;
            color: $nav-side-bar-primary-color;
            border-radius: $navigation-border-radius;
            & > .title:hover {
                cursor: pointer;
            }
            & a {
                color: $nav-side-bar-primary-color;
            }
            & > .title.active {
                font-weight: $font-weight-bold;
                color: $edp-brand-purple;
            }
            & > .title {
                &:hover {
                    color: darken($edp-brand-purple, 8%);
                }
                &:active {
                    color: darken($edp-brand-purple, 15%);
                }
            }
        }
        &-sub-item {
            font-size: $font-size-sm;
            margin-left: 1.5em;
            margin-top: 0.2em;
            padding: 0.35em 0;
            &:first-child {
                margin-top: 0.5em;
            }
            &:hover {
                cursor: pointer;
                color: darken($edp-brand-purple, 8%);
            }
            & > span {
                &:active {
                    color: darken($edp-brand-purple, 15%);
                }
            }
            
            &.active {
                font-weight: $font-weight-bold;
                color: $edp-brand-purple;
            }
        }
        &-arrow {
            &-container {
                display: flex;
                flex-direction: row;
                justify-content: center;
                border-top: 1px solid #656565;
                padding-top: 15px;
                &:hover {
                    cursor: pointer;
                }
            }
            &-icon {
                margin: 0 0 2px 15px;
                height: 16px;
                width: 16px;
                float: right;
                stroke: $nav-side-bar-primary-color;
                &.up {
                    animation-name: rotateUp;
                    animation-duration: 0.4s;
                    animation-iteration-count: 1;
                    transform-origin: 50% 50%;
                    display: inline-block;
                    transform: rotate(180deg);
                }
                &.down {
                    animation-name: rotateDown;
                    animation-duration: 0.4s;
                    animation-iteration-count: 1;
                    transform-origin: 50% 50%;
                    display: inline-block;
                }
            }
        }
    }
}

@media screen and (min-width: $breakpoint-md) {
    .edp-nav-side-bar {
        &__secondary {
            &-item {
                min-width: 12em;
            }
        }
    }
}

@media screen and (max-width: $breakpoint-md) {
    .edp-nav-side-bar {
        &__secondary {
            &-item {
                min-width: 8em;
            }
        }
    }
}

@keyframes rotateUp {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(180deg);
    }
}

@keyframes rotateDown {
    0% {
        transform: rotate(180deg);
    }
    100% {
        transform: rotate(0deg);
    }
}