$user-permissions-table-padding: 30px 40px;
$user-permissions-background: #d8d8d8;
$user-permissions-background-darker: #c3c3c3;

// Material UI table overrides
.MuiTableRow-root.MuiTableRow-head {
    &:first-child {
        border-bottom: 2px solid $gray-400;
    }
}
button.MuiButtonBase-root {
    margin-right: 5px;
    &:focus {
        outline: none;
    }
}

.edp-user-permissions {
    background: $user-permissions-background;
    color: #353735;
    min-height: calc(100vh - #{$navbar-height});

    &__nav-column {
        background: $user-permissions-background-darker;
    }
    &__nav-column,
    &__body-column {
        min-height: calc(100vh - #{$navbar-height});
        transition: 0.5s all ease;
    }
    &__group {
        display: block;
        width: 50%;
        margin: 15px auto;
        text-align: center;
        background: #c3c3c3;
        border-radius: 5px;

        &--title {
            font-weight: $font-weight-bold;
        }
    }

    &__dropdown-menu {
        top: 2em !important;
        left: unset;
        transform: none !important;
        max-height: 30em;
        overflow-y: scroll;
    }

    &__input-field {
        background: darken($gray-300, 2%);
        border-color: darken($gray-300, 2%);
        &:focus {
            background: darken($gray-300, 2%);
            border-color: darken($gray-300, 2%);
            box-shadow: 0 0 0 0.2rem rgba(160, 160, 160, 0.25);
        }
        &.description {
            min-height: 90px;
        }
    }

    &__users {
        &-container {
            padding: $user-permissions-table-padding;
        }
    }

    &__groups {
        &-container {
            padding: $user-permissions-table-padding;
        }
        
    }

    &__table {
        &--row {
            &:hover.clickable {
                cursor: pointer;
                background: darken( $user-permissions-background, 1% );
            }
            &:active.clickable {
                background: darken( $user-permissions-background, 5% );
            }
        }
        &--header-cell {
            &.userName,
            &.roleName,
            &.groupName {
                width: 22em;
            }
        }
    }


    &__details-page {
        &--title {
            font-size: $font-size-xxl;
        }
        &--subtitle {
            font-size: $font-size-lg;
        }
        &--table {
            border-collapse: collapse;
            width: 100%;
            border: 1px solid $gray-400;
            text-align: left;

            &-cell,
            &-header {
                border: 1px solid $gray-400;
                text-align: left;
                padding: 15px;
            }
            &-header {
                width: 9em;
            }
        }
    }

    &__add-button {
        padding: 0;
        border: none;
        background: transparent;
        &:focus {
            outline: none;
        }
        &.green {
            color: $brand-success;
            &:hover {
                color: darken( $brand-success, 2% );
            }
            &:active {
                color: darken( $brand-success, 5% );
            }
        }
    }

    &__button,
    &__remove-button,
    &__add-button {
        &.disabled {
            &:hover {
                cursor: not-allowed;
            }
        }
    }

    &__remove-button {
        padding: 0;
        border: none;
        background: transparent;
        &:focus {
            outline: none;
        }
        &.grey {
            color: lighten( $dark-font-color, 5% );
            &:hover {
                color: $dark-font-color;
            }
            &:active {
                color: darken( $dark-font-color, 15% );
            }
        }
        & > svg {
            margin-bottom: 2px;
            margin-left: 2px;
            height: 0.75em;
            width: 0.75em;
        }
    }
    
    &__button  {
        font-size: $font-size-sm;
        padding: 4px 16px;
        border-radius: 5px;
        border: 1px solid $gray-600;
        color: $gray-600;
        background: transparent;
        margin-bottom: 15px;

        & > svg {
            margin-right: 2px;
        }

        &:hover {
            background: $gray-600;
            font-weight: $font-weight-bold;
            border: 1px solid transparent;
            color: $light-font-color;
        }
        &:focus {
            outline: none;
        }
        &:active {
            background: darken( $gray-600, 5%);
        }

        

        &--icon {
            &.trash-can {
                width: 15px;
                height: 15px;
                margin-bottom: 3px;
                margin-right: 5px;
                fill: $red-alert-color;
            }
        }
        &.mini {
            margin-left: 5px;
            padding: 2px 8px;
        }

        // alignment variations
        &.right {
            float: right;
        }

        // color variations
        &.red {
            border-color: $red-alert-color;
            color: $red-alert-color;
            & svg {
                fill: $red-alert-color;
            }
            &:hover {
                background: $red-alert-color;
                color: $light-font-color;
                & svg {
                    fill: $light-font-color;
                }
            }
            &:active {
                background: darken( $red-alert-color, 5% );
            }
        }
        &.green {
            border-color: $brand-success;
            color: $brand-success;
            &:hover {
                background:  $brand-success;
                color: $light-font-color;
            }
            &:active {
                background: darken(  $brand-success, 5% );
            }
        }
    }

    &__table-cell {
        &--group,
        &--role,
        &--is-active {
            font-size: $font-size-xs;
            font-weight: $font-weight-bold;
            display: inline-block;
            padding: 5px 15px;
            margin: 4px;
            border-radius: 5px;
        }
        &--is-active {
            &.green {
                background: $green-alert-color;
            }
            &.red {
                background: $red-alert-color;
            }
        }
        &--group,
        &--role {
            background: $gray-400;
        }
    }
}


// nav side bar styling
@media screen and (min-width: $breakpoint-xl) {
    .edp-user-permissions {
        &__nav-column,
        &__body-column {
            padding: 0 calc(#{$nav-side-bar-width} / 2);
        }
        &__nav-column {
            &.navbar-open {
                padding-left: calc(#{$nav-side-bar-width});
                padding-right: 0;
            }
        }
    }
}