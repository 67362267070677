.parking-card {
    text-align: center;
    margin-right: 0px;
    margin-left: 0px;

    &__field-title {
        color: $card-title-color;
        font-size: $font-size-md;
    }
    &__percentage {
        font-size: $font-size-xl;
        margin-top: 5px;
    }
    &__indicator {
        padding-left: 10px;
        border-left: 5px solid transparent;
        &.green {
            border-left-color: $green-alert-color;
        }
        &.amber {
            border-left-color: $amber-alert-color;
        }
        &.red {
            border-left-color: $red-alert-color;
        }
    }
    .edp-card--footer {
        margin-left: 0px;
    }
}

@media screen and (min-width: $breakpoint-xl) {
    .parking-card {
        &__field-title {
            font-size: $font-size-md-dynamic;
        }
        &__percentage {
            font-size: 1.75rem; // half-way between $font-size-lg and $font-size-xl
        }
    }
}

@media screen and (max-width: $breakpoint-xl) {
    .parking-card {
        &__field-title {
            font-size: $font-size-sm;
        }
        &__percentage {
            font-size: calc(2vh);
        }
    }
}