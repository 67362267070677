@media screen and (max-width: $breakpoint-xl) {
    .edp-card {
        &__notification {
            font-size: $font-size-xs;
        }
    }
}

@media screen and (min-width: $breakpoint-xl) {
    .edp-card {
        &__notification {
            font-size: $font-size-sm;
        }
    }
}
